import React from 'react';

class ButtonLogin extends React.Component {

    render() {
        return (
            <div className="navbar-nav nav-flex-icons menu-link">
                <li className="nav-item">
                    <a className="nav-link" href="/Login">Accedi</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/Register">Registrati</a>
                </li>
            </div>
        )
    }
}

export default ButtonLogin;