
import React from 'react';
import SearchBar from './SearchBar'
import Navbar from './Navbar';
import Footer from './Footer';
import loadjs from 'loadjs';
import PageLoader from './PageLoader';
import GoogleMapSearch from './GoogleMapSearch';

class SearchPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companies : {data:[], outTotalRecordNumber: 0},
            resultMsg:'Cerca tra gli esercenti intorno a te!',
            //center : {},
            googleMapSearch: [],
            companiesPanel: [],
            isLoading: false
        };
        this.goToDetail = this.goToDetail.bind(this);
        this.scrollToResults = this.scrollToResults.bind(this);
    }

    componentDidMount()
    {        
        loadjs(['./assets/js/custom.js'], () => {});
    }
    isLoadingFunction = () =>{
        this.setState({ isLoading: true });
    }

    callbackFunction = (companiesData, lon, lat) => {
        this.setState({ isLoading: false });
        if(companiesData)
        {
            this.setState({ googleMapSearch: [] });
            this.setState({ companies: companiesData ?? {data:[], outTotalRecordNumber: 0} });
            if(companiesData.outTotalRecordNumber <= 0)
            {
                this.setState({ resultMsg: 'Nessun servizio trovato, prova a cambiare i filtri di ricerca.' });
                this.setState({ companiesPanel: [] });
            }
            else
            {
                if(companiesData.outTotalRecordNumber > 1)
                {
                    this.setState({ resultMsg: 'La ricerca ha restituito ' + (companiesData.outTotalRecordNumber ?? 0) + ' risultati.' });
                }
                else
                {
                    this.setState({ resultMsg: 'La ricerca ha restituito ' + (companiesData.outTotalRecordNumber ?? 0) + ' risultato.' });
                }                
                //
                const points = [];
                let companiesPanel = [];
                var companies = companiesData;
                if(companies && companies.data)
                {
                    for(var i=0;i< companies.outTotalRecordNumber; i ++ )
                    {
                        var item = companies.data[i];
                        var key = "companystorekey" + i;
                        points.push([{id: i, letter: ""+(i+1)+"", tooltip: item.company.name, lat: item.lat, lng: item.lon, description: item.company.name +" "+item.address + " a " + (item.distanceMeters/1000).toFixed(2) + " Km dall'indirizzo scelto" }])
                        var c = [ <>
                    <div key={key} className="card-body">
                    <div className="row">
                        <div className="col-1 d-flex align-items-center">
                        <span style={{ color: "#f26d7d", fontSize: 25 + "px", textAlign: "center" }}> {i+1}</span>
                        </div>
                        <div className="col" style={{textAlign: "left"}}>
                        <h4 className="font-weight-bold card-title">{item.company.name+ " "+ (item.company.companyCategories != null ? "- "+item.company.companyCategories[0].description : '' )}</h4>
                            <p className="card-text">{item.address+"."}</p>
                            <span>{"Dista "+ (item.distanceMeters/1000).toFixed(2) + " Km dall'indirizzo scelto. " + (item.company.description ?? '')}</span>
                        </div>
                        <div className="col-md-auto mt-2">
                            <button onClick={this.goToDetail} data-idenc={item.companyStoreIdEnc} className="btn btn-primary float-right">Dettaglio</button>
                        </div>
                    </div>
                </div>
                <hr/></>];
                    companiesPanel.push(c);
                    }
                    //companiesPanel
                    this.setState({ companiesPanel: companiesPanel });
                    this.setState({ googleMapSearch: [<GoogleMapSearch key="gmapsearch" points={points} center={{lat: lat, lng: lon }}/>] });
                } 
            }
        }
        else
        {
            this.setState({ resultMsg: 'La ricerca non ha prodotto risultati in nessuna azienda.' });
            this.setState({ companiesPanel: [] });
        }
        this.scrollToResults();
        loadjs(['./assets/js/custom.js'], () => {});
  }
  scrollToResults = () => {
    this.resultsSection.scrollIntoView({ behavior: "smooth" });
  }
  goToDetail(event)
  {    
    var idEnc = event.currentTarget.dataset.idenc;
    const { from } = { from: { pathname: "/Company/Detail/"+idEnc } };
    this.props.history.push(from);
  }
    render() {   
        
        const { companiesPanel, resultMsg, googleMapSearch, isLoading} = this.state; 
        if(isLoading)
            return <PageLoader/>
        return (
            <div className="ts-page-wrapper" id="page-top" style={{ minHeight: "100vh" }}>
                <Navbar/>
                <header id="ts-hero" className="ts-full-screen" style={{minHeight: 100+"vh"}}>
                    <div className="container align-self-center">
                        <div className="row d-flex justify-content-center">
                            <h3>Ricerca il servizio</h3>
                        </div>
                        <div className="row align-items-center d-flex justify-content-center row-up">
                            <SearchBar parentCallback = {this.callbackFunction} isLoadingCallback = {this.isLoadingFunction} />
                        </div>
                        <div className="row d-flex justify-content-center" >
                            <p style={{color: "#fff", fontSize:22+"px", textAlign: "center"}}>{resultMsg}</p>
                        </div>
                        
                    </div>
                    <div id="ts-dynamic-waves" className="ts-background" data-bg-color="#1a1462">
                        <svg className="ts-svg ts-parallax-element" width="100%" height="100%">
                            <defs></defs>
                            <path className="ts-dynamic-wave" d="" data-wave-color="#dd4b5b" data-wave-height=".6"
                                data-wave-bones="2" data-wave-speed="0.15" />
                        </svg>
                        <svg className="ts-svg" width="100%" height="100%" >
                            <defs></defs>
                            <path className="ts-dynamic-wave" d="" data-wave-color="#dd4b5b" data-wave-height=".0" data-wave-bones="1"
                                data-wave-speed="0.20" />
                        </svg>
                    </div>
                </header>

                <main id="ts-content" style={{backgroundColor: "#dd4b5b"}} >
                    <section className="text-center"style={{paddingTop: 2+"rem"}}>
                        <div ref={(el) => { this.resultsSection = el; }} className="container">
                            {googleMapSearch}
                            <div className="card">
                                {companiesPanel}
                            </div>
                            <div className="row" >
                                
                            </div>
                        </div>
                    </section>
                </main>
                <Footer/>
            </div>

        );
    }
}

export default SearchPage;


























