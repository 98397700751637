import React from 'react';
import Modal from './modal';

class SettingsCompanyTab extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            Name: '',
            VatNumber: '',
            FiscalCode: '',
            Description: '',
            Category: '',
            disabled: true,
            verifyEmail: '',
            showVerifyEmail: false, 
            verifyPhoneNumber: '',
            showVerifyPhoneNumber: false, 
        };  
        //Field onChange
        this.handleChange = this.handleChange.bind(this);
        //Fields onClick
        this.handleClickFields = this.handleClickFields.bind(this);   
        this.handleClickFieldsCancel = this.handleClickFieldsCancel.bind(this);

        this.handleClickEmail = this.handleClickEmail.bind(this);   
        this.handleClickEmailCancel = this.handleClickEmailCancel.bind(this);

        this.handleClickPhoneNumber = this.handleClickPhoneNumber.bind(this);   
        this.handleClickPhoneNumberCancel = this.handleClickPhoneNumberCancel.bind(this);   
    }

    handleChange(event) {
        const { name, value } = event.target
        this.setState({[name]: value});
    }

    //verify fields
    handleClickFields(event) {
        this.setState({ disabled: false })
        const { Name, VatNumber, FiscalCode, Description, Category, disabled } = this.state;
        if (!disabled) {
            if (Name != 'test' && VatNumber != 'test' && FiscalCode != 'test' && Description != 'test' && Category != 'test'/* current data from API*/) {

            } else this.ModalError(`già in uso.`);
        }
    }
    handleClickFieldsCancel(event){this.setState( {disabled: true} )}

    //Verify Eamil
    handleClickEmail(event){
        this.setState({ showVerifyEmail: true });
        const { showVerifyEmail, verifyEmail } = this.state
        console.log(showVerifyEmail)
        if (showVerifyEmail) {
            if (this.isRequiredErrorFieldInput(verifyEmail, "Verifica Email")) {
                const email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (email.test(verifyEmail)) {
                    if (verifyEmail === /*current Email from API*/ 'email@email.it') {

                    }
                } else this.ModalError(`Assicurati che Email sia corretto.`);
            } else this.ModalError(`Assicurati che Email corrispondano.`);
        }
    }
    handleClickEmailCancel(event){ this.setState({ showVerifyEmail: false });}

    //Verify Phonenumber
    handleClickPhoneNumber(event){
        this.setState({ showVerifyPhoneNumber: true });
        const { showVerifyPhoneNumber, verifyPhoneNumber } = this.state
        if (showVerifyPhoneNumber) {
            if (this.isRequiredErrorFieldInput(verifyPhoneNumber, "Verifica Numero di telefono")) {
                var number = /^[0-9]+$/;
                if (verifyPhoneNumber === /*current PhoneNumber from API*/ '123') {
                    if (number.test(verifyPhoneNumber)) {

                    } else this.ModalError(`Assicurati che il numero di telefono sia corretto.`);
                } else this.ModalError(`Assicurati che il numero di telefono corrispondano.`);
            }
        }
    }
    handleClickPhoneNumberCancel(event){this.setState({ showVerifyPhoneNumber: false });}

    //ModalError
    ModalError(nameField) {
        this.setState({
            classtype: 'modal-error',
            icon: 'fa fa-close',
            description: nameField
        }, () => {
            window.$('#fielderrorChangePsw').modal('show');
        });
    }

    //Input Modal Error
    isRequiredErrorFieldInput(value, nameField) {
        var status_ok = false;
        if (value !== "") status_ok = true;
        else this.ModalError(`il Campo ${nameField} è obbligatorio.`);
        return status_ok
    }

    render() {
        const {showVerifyEmail, showVerifyPhoneNumber, disabled} = this.state;
        const modifyEmail = (
            <div className="row" >
                <div className="col">
                    <div className="form-group">
                        <label >Verifica Email</label>
                        <input type="email" className="form-control" name="verifyEmail" onChange={this.handleChange} placeholder="Verifica Email" />
                    </div>
                </div>
            </div>
        )
        const modifyPhoneNumber = (
            <div className="row" >
                <div className="col">
                    <div className="form-group">
                        <label >Verifica Numero di Telefono</label>
                        <input className="form-control" type="tel" name="verifyPhoneNumber" onChange={this.handleChange} placeholder="Verifica Numero di Telefono" />
                    </div>
                </div>
            </div>
        )
        return (
            <div className="container pt-4" >
                <Modal id="fielderrorChangePsw" classtype={this.state.classtype} icon={this.state.icon} title={this.state.title} description={this.state.description}  />
                <div className="row">
                    <div className="col-md-6 col-sm-6">
                        <div className="form-group">
                            <label >Nome Attività</label>
                            <input type="text" className="form-control" name="Name" onChange={this.handleChange} placeholder="Ragione Sociale" disabled = {disabled ? 'disabled' : ''}/>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="form-group">
                            <label >Partita Iva</label>
                            <input type="text" className="form-control" name="VatNumber" onChange={this.handleChange} placeholder="Partita Iva" disabled = {disabled ? 'disabled' : ''}/>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="form-group">
                            <label >Codice Fiscale</label>
                            <input type="text" className="form-control" name="FiscalCode" onChange={this.handleChange} placeholder="Codice Fiscale" disabled = {disabled ? 'disabled' : ''}/>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="form-group">
                            <label >Settore *</label>
                            <select className="form-control" name="Category" onChange={this.handleChange} placeholder="Codice Ateco" disabled = {disabled ? 'disabled' : ''}>
                                <option></option>
                            </select>
                        </div>
                    </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label >Descrizione Attività o Servizi</label>
                                <textarea type="text" className="form-control" rows="3" name="Description" onChange={this.handleChange} placeholder="Descrizione" disabled = {disabled ? 'disabled' : ''}/>
                            </div>
                        </div>
                    <div className="col">
                        <div className="form-group" >
                            {disabled ? null : <button className="btn btn-secondary float-right ml-2" onClick={this.handleClickFieldsCancel} >Annulla</button>}
                            <button className="btn btn-primary float-right" onClick={this.handleClickFields} >{!disabled ? 'Salva' : 'Modifica'}</button>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md col-sm">
                        <div className="form-group">
                            <label >Email</label>
                            <input type="email" className="form-control" name="Email" placeholder="Email" disabled/>
                        </div>
                    </div>
                </div>
                    {showVerifyEmail ? modifyEmail : null}
                <div className="row">
                    <div className="col">
                        <div className="form-group" >
                            {showVerifyEmail ? <button className="btn btn-secondary float-right ml-2" onClick={this.handleClickEmailCancel} >Annulla</button> : null}
                            <button className="btn btn-primary float-right" id="btn-modif-email" onClick={this.handleClickEmail} >{showVerifyEmail ? 'Salva' : 'Modifica'}</button>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label >Telefono</label>
                            <input className="form-control" type="tel" id="phonenumber" name="PhoneNumber" placeholder="Numero di Telefono" disabled />
                        </div>
                    </div>
                </div>
                    {showVerifyPhoneNumber ? modifyPhoneNumber : null}
                <div className="row">
                    <div className="col">
                        <div className="form-group clearfix" >
                            {showVerifyPhoneNumber ? <button className="btn btn-secondary float-right ml-2" onClick={this.handleClickPhoneNumberCancel} >Annulla</button> : null}
                            <button className="btn btn-primary float-right" onClick={this.handleClickPhoneNumber} >{showVerifyPhoneNumber ? 'Salva' : 'Modifica'}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SettingsCompanyTab;