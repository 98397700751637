import React from 'react';

class PageLoader extends React.Component {

    render() {
        return (
            <div style={{height:100+'vh' ,backgroundColor: 'rgb(26, 20, 98)'}}>
                <div className="loader-container" >
                    <div className="loader-chase">
                        <div className="loader-chase-dot"></div>
                        <div className="loader-chase-dot"></div>
                        <div className="loader-chase-dot"></div>
                        <div className="loader-chase-dot"></div>
                        <div className="loader-chase-dot"></div>
                        <div className="loader-chase-dot"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageLoader;