import React from 'react';
import $ from 'jquery';
import Modal from './modal';
import environment from '../environment';
import process from "process";
import loadjs from 'loadjs';

//URL PRODUTION AND LOCAL
//const UrlProd = environment.configs.APIurlProd;
//const UrlLocal = environment.configs.ApiyrlLocal;

class UserRegisterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            FirstName: '',
            LastName: '',
            Email: '',
            PhoneNumber: '',
            Password: '',
            ConfirmPsw: '',
            //Privacy
            PrivacyCheck: false,
            CompanyRulesCheck: false,
            //Modal
            idModal: '',
            classtype: '',
            icon: '',
            title: '',
            description: '',
            //InfoModel
            idInfoModal: '',
            textInfoModal: '',
            textstrongInfoModel: '',
            showModal : false
        };
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            this.Url = environment.configs.APIurlLocal;
        } else {
            this.Url =environment.configs.APIurlProd;
        }
        //Fields onChange
        this.handleChange = this.handleChange.bind(this);
        //CHECK privacy
        this.handleChangePrivacyCheck = this.handleChangePrivacyCheck.bind(this);
        this.handleChangeCompanyRulesCheck = this.handleChangeCompanyRulesCheck.bind(this);
        //Submit
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    //Init RequiredModal
componentDidMount() { loadjs(['./assets/js/custom.js'], () => {}); }

    componentDidUpdate(prevState) {
        if(prevState.idModal !== this.state.idModal && prevState.description !== this.state.description && (this.state.idModal && this.state.description))
        {
            console.log(prevState.idModal)
            console.log(this.state.idModal)
            window.$('#'+this.state.idModal).modal('show');
        }
    }
    //Fields
    handleChange(event) {
        if (event.target.name === 'FirstName') this.setState({ FirstName: event.target.value });
        if (event.target.name === 'LastName') this.setState({ LastName: event.target.value });
        if (event.target.name === 'Email') this.setState({ Email: event.target.value });
        if (event.target.name === 'PhoneNumber') this.setState({ PhoneNumber: event.target.value });
        if (event.target.name === 'Password') this.setState({ Password: event.target.value });
        if (event.target.name === 'ConfirmPsw') this.setState({ ConfirmPsw: event.target.value });
    }

    //CheckBox onChange Privacy
    handleChangePrivacyCheck(event) { this.setState({ PrivacyCheck: event.target.checked }); }
    handleChangeCompanyRulesCheck(event) { this.setState({ CompanyRulesCheck: event.target.checked }); }
    
    //ModalError
    ModalError(description){
        this.setState({            
            description: description
        }, () => {
            window.$('#fielderrorUser').modal('show');
        });
    }

    //Input Modal Error
    isRequiredErrorFieldInput(value, nameField) {
        var status_ok = false;
        if (value !== "") status_ok = true;
        else this.ModalError(`il Campo ${nameField} è obbligatorio.`);
        return status_ok
    }

    //Checkbox Modal Error
    isRequiredErrorFieldCheckbox(checked, nameField) {
        var status_ok = false;
        if (checked === true)status_ok = true; 
        else this.ModalError(`il Campo ${nameField} è obbligatorio.`);
        return status_ok
    }
    

    handleSubmit(event) {
        event.preventDefault();
        let that = this;
        if (this.isRequiredErrorFieldInput(this.state.FirstName, "Nome") && this.isRequiredErrorFieldInput(this.state.LastName, "Cognome") &&
            this.isRequiredErrorFieldInput(this.state.Email, "Email") && this.isRequiredErrorFieldInput(this.state.PhoneNumber, "Telefono ") &&
            this.isRequiredErrorFieldInput(this.state.Password, "Password") && this.isRequiredErrorFieldInput(this.state.ConfirmPsw, "Conferma Password") &&
            this.isRequiredErrorFieldCheckbox(this.state.PrivacyCheck, "Informativa sulla Privacy") && this.isRequiredErrorFieldCheckbox(this.state.CompanyRulesCheck, "Regole d'uso per l'utente")) {
            //Password structure
            var letter = /[a-z]/;
            var upper  =/[A-Z]/;
            var number = /[0-9]/;
            var specialLetter = /[#?!@$%^&*-]/;
            var length =/.{8,}/
            if (this.state.Password === this.state.ConfirmPsw) {
                if (length.test(this.state.Password)) {
                    if (letter.test(this.state.Password)) {
                        if (upper.test(this.state.Password)) {
                            if (specialLetter.test(this.state.Password)) {
                                if (number.test(this.state.Password)) {
                                    $("form :input").prop("disabled", true);
                                    const formObj = {
                                        'FirstName': this.state.FirstName,
                                        'LastName': this.state.LastName,
                                        'Email': this.state.Email,
                                        'Password': this.state.Password,
                                        'PhoneNumber': this.state.PhoneNumber,
                                        'TimeZoneId': 'Europe/Rome'/*new Date().toString().replace( /(^.*\(|\).*$)/g, '' )*/
                                    };
                                    $.ajax({
                                        type: 'post',
                                        url: `${that.Url}/User/Register`,
                                        headers: { "Content-Type": "application/json" },
                                        dataType: "json",
                                        data: JSON.stringify(formObj),
                                        success: function (response) {
                                            $("form :input").prop("disabled", false);
                                            window.$("#userModelSuccess").modal("show");},                                          
                                        error: function (response) {
                                            $("form :input").prop("disabled", false);
                                            window.$("#userModelError").modal("show");},         
                                        }
                                    );
                                } else {this.ModalError(`Assicurati che la password includa una cifra`)};
                            }else {this.ModalError(`Assicurati che la password includa una lettera speciale`)};
                        }else {this.ModalError(`Assicurati che la password includa una lettera maiuscola.`)};
                    } else {this.ModalError(`Assicurati che la password includa una lettera minuscola.`)};
                } else {this.ModalError(`Assicurati che la password sia più lunga di 8 caratteri.`)};
            }else {this.ModalError(`Assicurati che le password corrispondano.`)};
        }
    }

    render() {       
        var description = this.state.description;
        return (
            <div>
                <Modal id="fielderrorUser" classtype="modal-error" icon="fa fa-close" title="Errore" description={description} />
                <Modal id="userModelSuccess" classtype='modal-success' icon='fa fa-check' title='' description='Registrazione avvenuta con successo!' />
                <Modal id="userModelError" classtype='modal-error' icon='fa fa-close' title='Errore!' description='Registrazione fallita riprovare.' />         
                <section className="ts-separate-bg-element" data-bg-image="/assets/img/bg-desk.jpg" data-bg-image-opacity=".1" data-bg-color="#1b1464">
                    <div className="container">
                        <div className="ts-box mb-0 p-5 ts-mt__n-10">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3>Registrazione Utente</h3>
                                    <form id="form-user-register" onSubmit={this.handleSubmit} className="clearfix ts-form ts-form-email ts-inputs__transparent">
                                        <div className="row">
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="form-contact-lastName">Nome *</label>
                                                    <input type="text" className="form-control" id="form-contact-firstname" onChange={this.handleChange} name="FirstName" placeholder="Nome" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="form-contact-lastname">Cognome *</label>
                                                    <input type="text" className="form-control" id="form-contact-lastname" name="LastName" onChange={this.handleChange} placeholder="Cognome" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="form-contact-email">Email *</label>
                                                    <input className="form-control" id="form-contact-email" type="email" name="Email" onChange={this.handleChange} placeholder="Email" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="form-contact-phone">Telefono *</label>
                                                    <input className="form-control" id="form-contact-phone" type="tel" name="PhoneNumber" onChange={this.handleChange} placeholder="Telefono" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="form-contact-password">Password *</label><i className="fa fa-info-circle float-right" data-toggle="tooltip" data-placement="top" title="Almeno un numero
Almeno un carattere alfanumerico minuscolo
Almeno un carattere alfanumerico maiuscolo
Almeno uno tra questi caratteri speciali: .,_-+*!#@
Lunghezza compresa tra 6 e 25 caratteri" ></i>
                                                    <input className="form-control" id="form-contact-password" type="password" name="Password"  onChange={this.handleChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="form-contact-ConfirmPsw">Conferma Password *</label><i className="fa fa-info-circle float-right" data-toggle="tooltip" data-placement="top" title="Almeno un numero
Almeno un carattere alfanumerico minuscolo
Almeno un carattere alfanumerico maiuscolo
Almeno uno tra questi caratteri speciali: .,_-+*!#@
Lunghezza compresa tra 6 e 25 caratteri" ></i>
                                                    <input className="form-control" id="form-contact-ConfirmPsw" type="password" name="ConfirmPsw" onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <div className="row">
                                                    <span style={{ color: "rgba(0, 0, 0, .5)" }}><input type="checkbox" value={this.state.PrivacyCheck} onChange={this.handleChangePrivacyCheck} />&nbsp;&nbsp;* Dichiaro di aver letto e di accettare il testo della <a href="/privacy">informativa sulla Privacy</a></span>
                                                </div>
                                                <br />
                                                <div className="row">
                                                    <span style={{ color: "rgba(0, 0, 0, .5)" }}><input type="checkbox" value={this.state.CompanyRulesCheck} onChange={this.handleChangeCompanyRulesCheck} />&nbsp;&nbsp;* <a href="/rules">Regole d'uso per l'utente</a> </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group clearfix">
                                            <button className="btn btn-primary float-right" id="form-contact-submit">Registrati</button>
                                        </div>
                                        <div className="form-contact-status"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

export default UserRegisterForm;
