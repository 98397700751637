import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import Modal from './modal';
import {Link } from "react-router-dom";
import InfoModal from './InfoModal'
import environment from '../environment'
import process from "process";
import loadjs from 'loadjs';

class CompanyForm extends React.Component {
    constructor(props) {
        super(props);
        //URL PRODUTION AND LOCAL
        
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            this.Url = environment.configs.APIurlLocal;
        } else {
            this.Url =environment.configs.APIurlProd;
        }
        this.google = window.google;
        this.autocompleteInput = React.createRef();
        this.autocomplete = null;

        this.state = {
            FirstName: '',LastName: '',Email: '', PhoneNumber: '',
            Name: '', VatNumber: '', FiscalCode: '', CompanyCategoryId: 0,
            Description: '', CompanyEmail: '', CompanyPhoneNumber: '',
            Password: '', ConfirmPsw: '',
            Lon: '', Lat: '', Address: '',
            ListCompanyCategory: [],
            ImageLogo: null,
            CompanyWeekday: [],
            //Privacy
            PrivacyCheck: false,
            UserRulesCheck: false,
            //Modal
            idModal: '',
            classtype: '', 
            icon: '',
            title: '',
            description: '',
            //InfoModel
            idInfoModal: '',
            textInfoModal: '',
            textstrongInfoModel: '',
            //table Time fields
            From_1_mon: '',To_1_mon: '',From_2_mon: '', To_2_mon: '',
            From_1_tue: '',To_1_tue: '',From_2_tue: '', To_2_tue: '',
            From_1_wed: '',To_1_wed: '',From_2_wed: '', To_2_wed: '',
            From_1_thu: '',To_1_thu: '',From_2_thu: '', To_2_thu: '',
            From_1_fri: '',To_1_fri: '',From_2_fri: '', To_2_fri: '',
            From_1_sat: '',To_1_sat: '',From_2_sat: '', To_2_sat: '',
            From_1_sun: '',To_1_sun: '',From_2_sun: '', To_2_sun: '',
        };
        //Fields onChange
        this.handleChange = this.handleChange.bind(this);
        this.handleChangecompanyAddresses = this.handleChangecompanyAddresses.bind(this);
        this.handleFieldTime = this.handleFieldTime.bind(this);
        //OnClick
        this.handleOnClickClosingDayInfo = this.handleOnClickClosingDayInfo.bind(this);
        //Table CheckBox
        this.handleCheckWeekly = this.handleCheckWeekly.bind(this);
        //CHECK privacy
        this.handleChangePrivacyCheck = this.handleChangePrivacyCheck.bind(this);
        this.handleChangeUserRulesCheck = this.handleChangeUserRulesCheck.bind(this);
        //Submit
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        loadjs(['./assets/js/custom.js'], () => {});
        //Autocoplete address
        this.autocomplete = new this.google.maps.places.Autocomplete(this.autocompleteInput.current,
            { types: ['address']});
        this.autocomplete.addListener('place_changed', this.handleChangecompanyAddresses);
        //GET categorie list
        $.ajax({
            type: "GET",
            url: `${this.Url}/company/get-categories`,
            dataType: 'json',
        }).done((data) => {   
            console.log(data)  
            this.setState({
                ListCompanyCategory: data.data ?? []
            });
        });
    }

    //Fields
    handleChange(event) { 
        if(event.target.name === 'FirstName') this.setState({ FirstName: event.target.value });
        if(event.target.name === 'LastName') this.setState({ LastName: event.target.value });
        if(event.target.name === 'Email') this.setState({ Email: event.target.value });
        if(event.target.name === 'PhoneNumber') this.setState({ PhoneNumber: event.target.value });
        if(event.target.name === 'Name') this.setState({ Name: event.target.value });
        if(event.target.name === 'VatNumber') this.setState({ VatNumber: event.target.value });
        if(event.target.name === 'FiscalCode') this.setState({ FiscalCode: event.target.value });
        if(event.target.name === 'CompanyCategoryId') this.setState({ CompanyCategoryId: event.target.value });
        if(event.target.name === 'Description') this.setState({ Description: event.target.value });
        if(event.target.name === 'CompanyEmail') this.setState({ CompanyEmail: event.target.value });
        if(event.target.name === 'CompanyPhoneNumber') this.setState({ CompanyPhoneNumber: event.target.value });
        if(event.target.name === 'Password') this.setState({ Password: event.target.value });
        if(event.target.name === 'ConfirmPsw') this.setState({ ConfirmPsw: event.target.value });
        if(event.target.name === 'ImageLogo') this.setState({ ImageLogo: event.target.files[0] });
    }

    // Table Checkbox 
    handleCheckWeekly(event){
        if(event.target.name === 'CompanyWeekdays[0].IsClosingDay') {
            this.setState({
                IsClosingDayMon: event.target.checked,
                From_1_mon: '', To_1_mon: '', From_2_mon: '', To_2_mon: ''
            }, () => {
                if(this.state.IsClosingDayMon){ $("input[type=time].f_mon").val(''); $(".mon input[type=time]").addClass("disableddiv");
                } else $(".mon input[type=time]").removeClass("disableddiv");
            });
        }
        if(event.target.name === 'CompanyWeekdays[1].IsClosingDay') {
            this.setState({
                IsClosingDayTue: event.target.checked,
                From_1_tue: '', To_1_tue: '', From_2_tue: '', To_2_tue: '',
            }, () => {
                if(this.state.IsClosingDayTue) { $("input[type=time].f_tue").val(''); $(".tue input[type=time]").addClass("disableddiv"); }
                else $(".tue input[type=time]").removeClass("disableddiv");
            });
        }
        if(event.target.name === 'CompanyWeekdays[2].IsClosingDay') {
            this.setState({
                IsClosingDayWed: event.target.checked,
                From_1_wed: '', To_1_wed: '', From_2_wed: '', To_2_wed: '',
            }, () => {
                if(this.state.IsClosingDayWed) { $("input[type=time].f_wed").val(''); $(".wed input[type=time]").addClass("disableddiv"); } 
                else $(".wed input[type=time]").removeClass("disableddiv");
            });
        }
        if(event.target.name === 'CompanyWeekdays[3].IsClosingDay') {
            this.setState({
                IsClosingDayThu: event.target.checked,
                From_1_thu: '', To_1_thu: '', From_2_thu: '', To_2_thu: '',
            }, () => {
                if(this.state.IsClosingDayThu) { $("input[type=time].f_thu").val(''); $(".thu input[type=time]").addClass("disableddiv"); }
                else $(".thu input[type=time]").removeClass("disableddiv");
            });
        }
        if(event.target.name === 'CompanyWeekdays[4].IsClosingDay') {
            this.setState({
                IsClosingDayFri: event.target.checked,
                From_1_fri: '', To_1_fri: '', From_2_fri: '', To_2_fri: '',
            }, () => {
                if(this.state.IsClosingDayFri) { $("input[type=time].f_fri").val(''); $(".fri input[type=time]").addClass("disableddiv"); } 
                else $(".fri input[type=time]").removeClass("disableddiv");
            });
        }
        if(event.target.name === 'CompanyWeekdays[5].IsClosingDay') {
            this.setState({
                IsClosingDaySat: event.target.checked,
                From_1_sat: '', To_1_sat: '', From_2_sat: '', To_2_sat: '',
            }, () => {
                if(this.state.IsClosingDaySat) { $("input[type=time].f_sat").val(''); $(".sat input[type=time]").addClass("disableddiv"); }
                else $(".sat input[type=time]").removeClass("disableddiv");
            });
        }
        if(event.target.name === 'CompanyWeekdays[6].IsClosingDay') {
            this.setState({
                IsClosingDaySun: event.target.checked,
                From_1_sun : '', To_1_sun: '', From_2_sun: '', To_2_sun: '',
            }, () => {
                if(this.state.IsClosingDaySun) { $("input[type=time].f_sun").val(''); $(".sun input[type=time]").addClass("disableddiv"); }
                else $(".sun input[type=time]").removeClass("disableddiv");
            });
        }
    }

    //Table fields input Time
    handleFieldTime(event){
        if(event.target.name === 'CompanyWeekdays[0].From_1') this.setState({ From_1_mon: event.target.value });
        if(event.target.name === 'CompanyWeekdays[0].To_1_mon') this.setState({ To_1_mon: event.target.value });
        if(event.target.name === 'CompanyWeekdays[0].From_2_mon') this.setState({ From_2_mon: event.target.value });
        if(event.target.name === 'CompanyWeekdays[0].To_2_mon') this.setState({ To_2_mon: event.target.value });

        if(event.target.name === 'CompanyWeekdays[1].From_1') this.setState({ From_1_tue: event.target.value });
        if(event.target.name === 'CompanyWeekdays[1].To_1') this.setState({ To_1_tue: event.target.value });
        if(event.target.name === 'CompanyWeekdays[1].From_2') this.setState({ From_2_tue: event.target.value });
        if(event.target.name === 'CompanyWeekdays[1].To_2') this.setState({ To_2_tue: event.target.value });

        if(event.target.name === 'CompanyWeekdays[2].From_1') this.setState({ From_1_wed: event.target.value });
        if(event.target.name === 'CompanyWeekdays[2].To_1') this.setState({ To_1_wed: event.target.value });
        if(event.target.name === 'CompanyWeekdays[2].From_2') this.setState({ From_2_wed: event.target.value });
        if(event.target.name === 'CompanyWeekdays[2].To_2') this.setState({ To_2_wed: event.target.value });

        if(event.target.name === 'CompanyWeekdays[3].From_1') this.setState({ From_1_thu: event.target.value });
        if(event.target.name === 'CompanyWeekdays[3].To_1') this.setState({ To_1_thu: event.target.value });
        if(event.target.name === 'CompanyWeekdays[3].From_2') this.setState({ From_2_thu: event.target.value });
        if(event.target.name === 'CompanyWeekdays[3].To_2') this.setState({ To_2_thu: event.target.value });

        if(event.target.name === 'CompanyWeekdays[4].From_1') this.setState({ From_1_fri: event.target.value });
        if(event.target.name === 'CompanyWeekdays[4].To_1') this.setState({ To_1_fri: event.target.value });
        if(event.target.name === 'CompanyWeekdays[4].From_2') this.setState({ From_2_fri: event.target.value });
        if(event.target.name === 'CompanyWeekdays[4].To_2') this.setState({ To_2_fri: event.target.value });

        if(event.target.name === 'CompanyWeekdays[5].From_1') this.setState({ From_1_sat: event.target.value });
        if(event.target.name === 'CompanyWeekdays[5].To_1') this.setState({ To_1_sat: event.target.value });
        if(event.target.name === 'CompanyWeekdays[5].From_2') this.setState({ From_2_sat: event.target.value });
        if(event.target.name === 'CompanyWeekdays[5].To_2') this.setState({ To_2_sat: event.target.value });

        if(event.target.name === 'CompanyWeekdays[6].From_1') this.setState({ From_1_sun: event.target.value });
        if(event.target.name === 'CompanyWeekdays[6].To_1') this.setState({ To_1_sun: event.target.value });
        if(event.target.name === 'CompanyWeekdays[6].From_2') this.setState({ From_2_sun: event.target.value });
        if(event.target.name === 'CompanyWeekdays[6].To_2') this.setState({ To_2_sun: event.target.value });
    }

    //GET latitude and longitude from GOOGLE MAPS
    handleChangecompanyAddresses() {
        const place = this.autocomplete.getPlace();
        const lan = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        this.setState({ Lon: lng });
        this.setState({ Lat: lan });
        this.setState({ Address: place.formatted_address })
    }

    //OnClick
    handleOnClickClosingDayInfo(event) {
        //Info //Set modal custom values
        this.setState({
            //idInfoModal: 'CompanyModelInfoClosingDayInfo',
            textInfoModal: "Seleziona per indicare i giorni di chiusura dell'attività",
            textstrongInfoModel: '',
        }, () => {
            window.$('#CompanyModelInfoClosingDayInfo').modal('show');
        });
    }

    //ModalError
    ModalError(nameField){
        this.setState({
            classtype: 'modal-error',
            icon: 'fa fa-close',
            description: nameField
        }, () => {
            window.$('#fielderrorCompany').modal('show');
        });
    }

    //Input Modal Error
    isRequiredErrorFieldInput(value, nameField) {
        var status_ok = false;
        if (value !== "") status_ok = true;
        else this.ModalError(`il Campo ${nameField} è obbligatorio.`);
        return status_ok
    }

    //Checkbox Modal Error
    isRequiredErrorFieldCheckbox(checked, nameField) {
        var status_ok = false;
        if (checked === true) status_ok = true; 
        else this.ModalError(`il Campo checkbox ${nameField} è obbligatorio.`);
        return status_ok
    }

    //CheckBox onChange Privacy
    handleChangePrivacyCheck(event) {this.setState({PrivacyCheck: event.target.checked});}
    handleChangeUserRulesCheck(event) {this.setState({UserRulesCheck: event.target.checked});}

    handleSubmit(event) {    
        event.preventDefault();  
        if( this.isRequiredErrorFieldInput(this.state.FirstName, "Nome") && this.isRequiredErrorFieldInput(this.state.LastName, "Cognome") && 
        this.isRequiredErrorFieldInput(this.state.Email, "Email ") && this.isRequiredErrorFieldInput(this.state.PhoneNumber, "Telefono") &&
        this.isRequiredErrorFieldInput(this.state.Password, "Password") && this.isRequiredErrorFieldInput(this.state.ConfirmPsw, "Conferma Password") &&  
        this.isRequiredErrorFieldInput(this.state.Name, "Nome Azienda") && this.isRequiredErrorFieldInput(this.state.VatNumber, "Partita Iva") && /*
        this.isRequiredErrorFieldInput(this.state.FiscalCode, "Codice Fiscale") &&*/ this.isRequiredErrorFieldInput(this.state.CompanyCategoryId, "Settore") && 
        this.isRequiredErrorFieldInput(this.state.Address, "Indirizzo") && this.isRequiredErrorFieldInput(this.state.Description, "Descrizione") && 

        this.isRequiredErrorFieldCheckbox(this.state.PrivacyCheck, "informativa sulla Privacy") && this.isRequiredErrorFieldCheckbox(this.state.UserRulesCheck, "Regole d'uso per l'azienda") &&
        this.state.Lon !== "" && this.state.Lat !== ""  ) 
        {
            var letter = /[a-z]/;
            var upper = /[A-Z]/;
            var number = /[0-9]/;
            var specialLetter = /[#?!@$%^&*-]/;
            var length = /.{8,}/
            if (this.state.Password === this.state.ConfirmPsw) {
                if (length.test(this.state.Password)) {
                    if (letter.test(this.state.Password)) {
                        if (upper.test(this.state.Password)) {
                            if (specialLetter.test(this.state.Password)) {
                                if (number.test(this.state.Password)) {
                                    $("form :input").prop("disabled", true);
                                    let formData = new FormData();
                                    formData.append('Address', this.state.Address);
                                    formData.append('Lon', this.state.Lon);
                                    formData.append('Lat', this.state.Lat);
                                    formData.append('FirstName', this.state.FirstName);
                                    formData.append('LastName', this.state.LastName);
                                    formData.append('Email', this.state.Email);
                                    formData.append('Password', this.state.Password);
                                    formData.append('PhoneNumber', this.state.PhoneNumber);
                                    formData.append('Name', this.state.Name);
                                    formData.append('VatNumber', this.state.VatNumber);
                                    formData.append('FiscalCode', this.state.FiscalCode);
                                    formData.append('CompanyCategoryId', this.state.CompanyCategoryId);
                                    formData.append('Description', this.state.Description);
                                    formData.append('CompanyEmail', this.state.CompanyEmail);
                                    formData.append('CompanyPhoneNumber', this.state.CompanyPhoneNumber);
                                    formData.append('Logo', $('#Logo')[0].files[0]);//
                                    formData.append('TimeZoneId', 'Europe/Rome'/*new Date().toString().replace( /(^.*\(|\).*$)/g, '' )*/);
                                    for (var i = 0; i < 7; i++) {
                                        formData.append("CompanyWeekdays[" + i + "].WeekDay", $("#CompanyWeekdays\\[" + i + "\\]\\.WeekDay").val());
                                        formData.append("CompanyWeekdays[" + i + "].From_1", $("#CompanyWeekdays\\[" + i + "\\]\\.From_1").val());
                                        formData.append("CompanyWeekdays[" + i + "].To_1", $("#CompanyWeekdays\\[" + i + "\\]\\.To_1").val());
                                        formData.append("CompanyWeekdays[" + i + "].From_2", $("#CompanyWeekdays\\[" + i + "\\]\\.From_2").val());
                                        formData.append("CompanyWeekdays[" + i + "].To_2", $("#CompanyWeekdays\\[" + i + "\\]\\.To_2").val());
                                        if ($("#CompanyWeekdays\\[" + i + "\\]\\.IsClosingDay").is(":checked")) {
                                            formData.append("CompanyWeekdays[" + i + "].IsClosingDay", true);
                                        }
                                        else {
                                            formData.append("CompanyWeekdays[" + i + "].IsClosingDay", false);
                                        }
                                    }
                                    $.ajax({
                                        type: "POST",
                                        url: `${this.Url}/company/register`,
                                        data: formData,
                                        enctype: 'multipart/form-data',
                                        processData: false,
                                        contentType: false,
                                        success: function (response) {
                                            //console.log("OK")
                                            $("form :input").prop("disabled", false);
                                            window.$("#companyModelSuccess").modal("show");
                                        },
                                        error: function (response) {
                                            //console.log("BAD")
                                            $("form :input").prop("disabled", false);
                                            window.$("#companyModelError").modal("show");
                                        },
                                    });
                                } else this.ModalError(`Assicurati che la password includa una cifra`);
                            } else this.ModalError(`Assicurati che la password includa una lettera speciale`);
                        } else this.ModalError(`Assicurati che la password includa una lettera maiuscola.`);
                    } else this.ModalError(`Assicurati che la password includa una lettera minuscola.`);
                } else this.ModalError(`Assicurati che la password sia più lunga di 8 caratteri.`);
            } else this.ModalError(`Assicurati che le password corrispondano.`);
        }       
        return;
    }

    isValid(input) {
        var reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        return reg.test(input);
    }
   
    render() {
        return (
            <section  className="ts-separate-bg-element" data-bg-image="/assets/img/bg-desk.jpg"
                data-bg-image-opacity=".1" data-bg-color="#1b1464">
                <Modal id="companyModelSuccess" classtype='modal-success' icon='fa fa-check' title='' description='Registrazione avvenuta con successo!' />
                <Modal id="companyModelError" classtype='modal-error' icon='fa fa-close' title='Errore!' description='Registrazione fallita riprovare.' />
                <Modal id="fielderrorCompany" classtype={this.state.classtype} icon={this.state.icon} title={this.state.title} description={this.state.description} />
                <InfoModal id={this.state.idInfoModal} text={this.state.textInfoModal} textStrong={this.state.textstrongInfoModel} />
                <div className="container">
                    <div className="ts-box mb-0 p-5 ts-mt__n-10">
                        <div className="row">
                            <div className="col-md-12">
                                <h3>Registrazione Azienda</h3>
                                <form id="form-company-register" onSubmit={this.handleSubmit} className="clearfix ts-form ts-form-email ts-inputs__transparent">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label >Nome *</label>
                                                <input type="text" className="form-control" id="firstname" name="FirstName"  onChange={this.handleChange} placeholder="Nome" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label >Cognome *</label>
                                                <input type="text" className="form-control" id="lastname" name="LastName"  onChange={this.handleChange} placeholder="Cognome" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label >Email *</label>
                                                <input className="form-control" type="email" id="email" name="Email"  onChange={this.handleChange}  placeholder="Email" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label >Telefono *</label>
                                                <input className="form-control" type="tel" id="phonenumber"  name="PhoneNumber" onChange={this.handleChange}  placeholder="Numero di Telefono" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label >Password *</label><i className="fa fa-info-circle float-right" data-toggle="tooltip" data-placement="top" title="Almeno un numero
Almeno un carattere alfanumerico minuscolo
Almeno un carattere alfanumerico maiuscolo
Almeno uno tra questi caratteri speciali: .,_-+*!#@
Lunghezza compresa tra 6 e 25 caratteri" ></i>
                                                <input className="form-control" type="Password" name="Password" onChange={this.handleChange} placeholder="Password" />
                                                
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label >Conferma Password *</label><i className="fa fa-info-circle float-right" data-toggle="tooltip" data-placement="top" title="Almeno un numero
Almeno un carattere alfanumerico minuscolo
Almeno un carattere alfanumerico maiuscolo
Almeno uno tra questi caratteri speciali: .,_-+*!#@
Lunghezza compresa tra 6 e 25 caratteri" ></i>
                                                <input className="form-control" type="password" name="ConfirmPsw" onChange={this.handleChange} placeholder="Conferma Password" />
                                            </div>
                                        </div>



                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label >Nome Attività *</label>
                                                <input type="text" className="form-control" id="name" name="Name" onChange={this.handleChange} placeholder="Ragione Sociale" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label >Partita Iva *</label>
                                                <input type="text" className="form-control" id="vatnumber" name="VatNumber" onChange={this.handleChange} placeholder="Partita Iva" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label >Codice Fiscale</label>
                                                <input type="text" className="form-control" id="fiscalcode" name="FiscalCode" onChange={this.handleChange}  placeholder="Codice Fiscale" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label >Settore *</label>
                                                <select className="form-control" name="CompanyCategoryId" id="companycategoryid" onChange={this.handleChange} placeholder="Codice Ateco" >
                                                    <option></option>
                                                    {
                                                        this.state.ListCompanyCategory.map(item => (
                                                            <option key={item.value} value={item.value} readOnly={true}>{item.text}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div hidden className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label >Numero di Telefono dell'azienda</label>
                                                <input type="tel" id="companyphonenumber" className="form-control" rows="5" onChange={this.handleChange} name="CompanyPhoneNumber" placeholder="Numero di Telefono" />
                                            </div>
                                        </div>
                                        <div hidden className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label >Email dell'azienda</label>
                                                <input type="email" className="form-control" id="companyemail" name="CompanyEmail" onChange={this.handleChange} placeholder="Email" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label >Indirizzo *</label>
                                                <input type="text" ref={this.autocompleteInput} className="form-control" id="address" id="company-contact-address" name="Address" placeholder="Indirizzo" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label >Descrizione Attività o Servizi *</label>
                                                <textarea type="text" className="form-control" rows="3" name="Description" id="description" onChange={this.handleChange} placeholder="Descrizione" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label >Logo</label>
                                                <input type="file" name="Logo" id="Logo" className="form-control-file" onChange={this.handleChange} accept="image/png, image/jpeg" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{display: "none"}}>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label >Email della sede</label><i className="fa fa-question-circle float-right"/>
                                                <input type="email" className="form-control" id="companystoreemail" name="CompanyStoreEmail" placeholder="Email" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label>Numero di Telefono della sede</label><i className="fa fa-question-circle float-right"/>
                                                <input type="tel" className="form-control" id="companystorephonenumber" name="CompanyStorePhoneNumber" placeholder="Numero di Telefono" />
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr style={{ textAlign: "center" }}>
                                                    <th scope="col"></th>
                                                    <th scope="col">Apertura</th>
                                                    <th scope="col">Chiusura</th>
                                                    <th scope="col">Apertura</th>
                                                    <th scope="col">Chiusura</th>
                                                    <th scope="col"><i className="fa fa-calendar-times-o" style={{fontSize:20+"px", cursor: "pointer"}} data-toggle="modal" onClick={this.handleOnClickClosingDayInfo} title="Giorni di chiusura"></i></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="mon">
                                                    <th scope="row" style={{ verticalAlign: "middle" }}>Lun</th>
                                                    <td hidden><input hidden name="CompanyWeekdays[0].WeekDay" id="CompanyWeekdays[0].WeekDay" value="0" readOnly={true} /></td>                                                    
                                                    <td><input type="time" name="CompanyWeekdays[0].From_1" id="CompanyWeekdays[0].From_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_mon" /></td>
                                                    <td><input type="time" name="CompanyWeekdays[0].To_1" id="CompanyWeekdays[0].To_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_mon" /></td>
                                                    <td><input type="time" name="CompanyWeekdays[0].From_2" id="CompanyWeekdays[0].From_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_mon" /></td>
                                                    <td><input type="time" name="CompanyWeekdays[0].To_2" id="CompanyWeekdays[0].To_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_mon" /></td>
                                                    <td style={{ verticalAlign: "middle", textAlign:"center" }} ><input type="checkbox" name="CompanyWeekdays[0].IsClosingDay" id="CompanyWeekdays[0].IsClosingDay" onChange={this.handleCheckWeekly} /></td>
                                                </tr>
                                                <tr className="tue">
                                                    <th scope="row" style={{ verticalAlign: "middle" }}>Mar</th>                                                    
                                                    <td hidden><input hidden name="CompanyWeekdays[1].WeekDay" id="CompanyWeekdays[1].WeekDay" value="1" readOnly={true} /></td>
                                                    <td><input type="time" name="CompanyWeekdays[1].From_1" id="CompanyWeekdays[1].From_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_tue" /></td>
                                                    <td><input type="time" name="CompanyWeekdays[1].To_1" id="CompanyWeekdays[1].To_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_tue" /></td>
                                                    <td><input type="time" name="CompanyWeekdays[1].From_2" id="CompanyWeekdays[1].From_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_tue" /></td>
                                                    <td><input type="time" name="CompanyWeekdays[1].To_2" id="CompanyWeekdays[1].To_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_tue" /></td>
                                                    <td style={{ verticalAlign: "middle", textAlign:"center" }}><input type="checkbox" name="CompanyWeekdays[1].IsClosingDay" id="CompanyWeekdays[1].IsClosingDay" onChange={this.handleCheckWeekly} /></td>
                                                </tr>
                                                <tr className="wed">
                                                    <th scope="row" style={{ verticalAlign: "middle" }}>Mer</th>                                                    
                                                    <td hidden><input hidden name="CompanyWeekdays[2].WeekDay" id="CompanyWeekdays[2].WeekDay" value="2" readOnly={true} /></td>
                                                    <td><input type="time" name="CompanyWeekdays[2].From_1" id="CompanyWeekdays[2].From_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_wed" /></td>
                                                    <td><input type="time" name="CompanyWeekdays[2].To_1" id="CompanyWeekdays[2].To_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_wed" /></td>
                                                    <td><input type="time" name="CompanyWeekdays[2].From_2" id="CompanyWeekdays[2].From_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_wed" /></td>
                                                    <td><input type="time" name="CompanyWeekdays[2].To_2" id="CompanyWeekdays[2].To_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_wed" /></td>
                                                    <td style={{ verticalAlign: "middle", textAlign:"center" }}><input type="checkbox" name="CompanyWeekdays[2].IsClosingDay" id="CompanyWeekdays[2].IsClosingDay" onChange={this.handleCheckWeekly} /></td>
                                                </tr>
                                                <tr className="thu">
                                                    <th scope="row" style={{ verticalAlign: "middle" }}>Gio</th>                                                    
                                                    <td hidden><input hidden name="CompanyWeekdays[3].WeekDay" id="CompanyWeekdays[3].WeekDay" value="3" readOnly={true} /></td>
                                                    <td><input type="time" name="CompanyWeekdays[3].From_1" id="CompanyWeekdays[3].From_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_thu" /></td>
                                                    <td><input type="time" name="CompanyWeekdays[3].To_1" id="CompanyWeekdays[3].To_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_thu" /></td>
                                                    <td><input type="time" name="CompanyWeekdays[3].From_2"  id="CompanyWeekdays[3].From_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_thu" /></td>
                                                    <td><input type="time" name="CompanyWeekdays[3].To_2" id="CompanyWeekdays[3].To_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_thu" /></td>
                                                    <td style={{ verticalAlign: "middle", textAlign:"center" }}><input type="checkbox" name="CompanyWeekdays[3].IsClosingDay" id="CompanyWeekdays[3].IsClosingDay" onChange={this.handleCheckWeekly} /></td>
                                                </tr>
                                                <tr className="fri">
                                                    <th scope="row" style={{ verticalAlign: "middle" }}>Ven</th>                                                    
                                                    <td hidden><input hidden id="CompanyWeekdays[4].WeekDay" name="CompanyWeekdays[4].WeekDay" value="4" readOnly={true} /></td>
                                                    <td><input type="time" id="CompanyWeekdays[4].From_1" name="CompanyWeekdays[4].From_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_fri" /></td>
                                                    <td><input type="time" id="CompanyWeekdays[4].To_1" name="CompanyWeekdays[4].To_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_fri" /></td>
                                                    <td><input type="time" id="CompanyWeekdays[4].From_2" name="CompanyWeekdays[4].From_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_fri" /></td>
                                                    <td><input type="time" id="CompanyWeekdays[4].To_2" name="CompanyWeekdays[4].To_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_fri" /></td>
                                                    <td style={{ verticalAlign: "middle_fri", textAlign:"center" }}><input type="checkbox" name="CompanyWeekdays[4].IsClosingDay" id="CompanyWeekdays[4].IsClosingDay" onChange={this.handleCheckWeekly} /></td>
                                                </tr>
                                                <tr className="sat">
                                                    <th scope="row" style={{ verticalAlign: "middle" }}>Sab</th>                                                    
                                                    <td hidden><input hidden name="CompanyWeekdays[5].WeekDay" id="CompanyWeekdays[5].WeekDay" value="5" readOnly={true} /></td>
                                                    <td><input type="time" name="CompanyWeekdays[5].From_1" id="CompanyWeekdays[5].From_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_sat" /></td>
                                                    <td><input type="time" name="CompanyWeekdays[5].To_1" id="CompanyWeekdays[5].To_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_sat" /></td>
                                                    <td><input type="time" name="CompanyWeekdays[5].From_2" id="CompanyWeekdays[5].From_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_sat" /></td>
                                                    <td><input type="time" name="CompanyWeekdays[5].To_2" id="CompanyWeekdays[5].To_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_sat" /></td>
                                                    <td style={{ verticalAlign: "middle", textAlign:"center" }}><input type="checkbox" name="CompanyWeekdays[5].IsClosingDay" id="CompanyWeekdays[5].IsClosingDay" onChange={this.handleCheckWeekly} /></td>
                                                </tr>
                                                <tr className="sun">
                                                    <th scope="row" style={{ verticalAlign: "middle" }}>Dom</th>                                                    
                                                    <td hidden><input hidden name="CompanyWeekdays[6].WeekDay" id="CompanyWeekdays[6].WeekDay" value="6" readOnly={true} /></td>
                                                    <td><input type="time" name="CompanyWeekdays[6].From_1" id="CompanyWeekdays[6].From_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_sun" /></td>
                                                    <td><input type="time" name="CompanyWeekdays[6].To_1" id="CompanyWeekdays[6].To_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_sun" /></td>
                                                    <td><input type="time" name="CompanyWeekdays[6].From_2" id="CompanyWeekdays[6].From_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_sun" /></td>
                                                    <td><input type="time" name="CompanyWeekdays[6].To_2" id="CompanyWeekdays[6].To_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control f_sun" /></td>
                                                    <td style={{ verticalAlign: "middle", textAlign:"center" }}><input type="checkbox" id="CompanyWeekdays[6].IsClosingDay" name="CompanyWeekdays[6].IsClosingDay" onChange={this.handleCheckWeekly} /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br></br>
                                    <div className="col">
                                        <div className="form-group">
                                            <div className="row">
                                                <span style={{ color: "rgba(0, 0, 0, .5)" }}><input type="checkbox" value={this.state.PrivacyCheck} onChange={this.handleChangePrivacyCheck} />&nbsp;&nbsp;* Dichiaro di aver letto e di accettare il testo della  <a href="/privacy">informativa sulla Privacy</a></span>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <span style={{ color: "rgba(0, 0, 0, .5)" }}><input type="checkbox" value={this.state.UserRulesCheck} onChange={this.handleChangeUserRulesCheck} />&nbsp;&nbsp;* <a href="/rules">Regole d'uso per l'azienda</a></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix" >
                                        <button className="btn btn-primary float-right">Registrami</button>
                                    </div>
                                    <div className="form-contact-status"></div>
                                </form>                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default CompanyForm;