import React from 'react';

class InputDay extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            choosenTime: "1"
        };  
        this.handleChange = this.handleChange.bind(this);    
    }

    handleChange(event)
    {
      this.setState({choosenTime: event.target.value});
      this.props.enableSecondStep(event.target.value)
    }
    render() {
        return (
            <div>
                <div className="text-white">
                    <label>Seleziona</label>
                    <select onChange={this.handleChange} value={this.state.choosenTime} className="form-control">
                        <option value="0">Fascia Mattutina</option>
                        <option value="1">Fascia Pomeridiana</option>
                        <option value="2">Fascia Serale</option>
                    </select>
                </div>
            </div>
        )
    }
}

export default InputDay;