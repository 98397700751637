import React from 'react';

class Modal extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            classtype: '',
            id: '',
            icon: '',
            title: '',
            description: ''
        };      
    }
    componentDidMount() {       
        this.setState({
            classtype: this.props.classtype,
            id: this.props.id,
            icon: this.props.icon,
            title: this.props.title,
            description: this.props.description
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.id !== prevProps.id || this.props.classtype !== prevProps.classtype || this.props.icon !== prevProps.icon|| this.props.title !== prevProps.title|| this.props.description !== prevProps.description || this.props.showModal !== prevProps.showModal) {
            this.componentDidMount();
        }
    }

    render() {     
        return (
        <div id={this.state.id} className="modal fade">
            <div className={`modal-dialog ${this.state.classtype}`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="icon-box">
                        <i className={this.state.icon}></i>
                        </div>
                        <h4 className="modal-title">{this.state.title}</h4>
                    </div>
                    <div className="modal-body">
                        <p className="text-center">{this.state.description}</p>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-success btn-block" data-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
}
export default Modal; 



