import React from 'react';

class InputNote extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            noteText : ""
        };     
        this.handleChange = this.handleChange.bind(this); 
        this.handleClick = this.handleClick.bind(this);
    }

    handleChange(event)
    {
        this.setState({noteText: event.target.value});
      this.props.enableThirdStep(event.target.value)
    }

    handleClick(event)
    {
        this.props.bookAppointment();
    }
    render() {
        return (
            <div>
                <div className="form-group text-white">
                    <label >Nota</label>
                    <textarea type="text" value={this.state.noteText} onChange={this.handleChange} className="form-control" rows="5" name="Note" placeholder="Nota" />
                    <br/>
                    <button onClick={this.handleClick} className="btn btn-primary float-right">Prenota Appuntamento</button>
                </div>
            </div>
        )
    }
}

export default InputNote;