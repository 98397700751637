import React from 'react';

class InfoModal extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            id: '',
            text: '',
            textStrong: '',
        };      
    }
    componentDidMount() {       
        this.setState({
            id: this.props.id,
            text: this.props.text,
            textStrong: this.props.textStrong,
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.id !== prevProps.id || this.props.text !== prevProps.text || this.props.textStrong !== prevProps.textStrong ) {
            this.componentDidMount();
        }
    }

    render(){
    return(
        <div className="modal fade" id={this.state.id} tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" >Informazione</h5>
                </div>
                <div className="modal-body">
                    <p>{this.state.text}<strong>{this.state.textStrong}</strong></p>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">chiudi</button>
                </div>
            </div>
        </div>
    </div>
    );
    }
}

export default InfoModal; 