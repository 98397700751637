import React from 'react';

const Marker = (props) => {
  
    const { color, name, id, letter, tooltip, description } = props;
    return (
      <div>
        <div
          onClick={() => props.showInfo(description)}
          className="pin bounce"
          style={{ backgroundColor: color, cursor: 'pointer' }}
          title={name} >
        <span style={{ fontSize: '90%', display: 'block' }} className="markerText" title={tooltip}>
          <br/>
            {letter}
        </span>
        </div>
        <div className="pulse" />
      </div>
    );
  };
  
  export default Marker;