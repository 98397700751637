import React from 'react';
import Navbar from './Navbar';
import loadjs from 'loadjs';
import Footer from './Footer';
import { userService } from '../services/user.service';
import PageLoader from './PageLoader';

class AppointmentDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateTime: '',
            bookingState: '',
            note: '',
            booking: null,
            userId: 0
        };
        //Field onChange
        this.handleChange = this.handleChange.bind(this);
        //Submit
        this.handleDelete = this.handleDelete.bind(this);
      }
    componentDidMount()
    {
        loadjs(['./assets/js/custom.js'], () => {});
        let user = JSON.parse(localStorage.getItem('user'));
        this.setState({
            userId: user.userId                     
        });
        var idEnc = this.props.match.params.bookingIdEnc;
        userService.getAppointment(idEnc).then(
            data => {
                if(data && data.data)
                {
                    console.log(data)
                    this.setState({
                        dateTime: data.data.when,
                        bookingState: data.data.bookingState,
                        note: '',
                        booking : data.data                     
                    });
                    loadjs(['./assets/js/custom.js'], () => {});
                }
                
            },
            error => {
                const { from } = { from: { pathname: "/GenericError" } };
                this.props.history.push(from);
            }
        ); 
    }
      handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
      }
        
      handleDelete(event) {
        const { from } = { from: { pathname: "/Booking/List" } };
        this.props.history.push(from);
        var idEnc = this.props.match.params.bookingIdEnc;
        userService.DeleteAppointment(idEnc).then(
            data => {
                if(data && data.ok)
                {
                    console.log(data)
                    this.props.history.push(from);
                }
                
            },
            error => {this.props.history.push(from);}
        ); 
      }
    render() {
        const {bookingState, note, dateTime, booking, userId} = this.state;
        if(!booking)        
            return (<PageLoader />)
            let notesListPanels = [];
        if(booking.messages)
        {
            for(var i=0;i<booking.messages.length;i++)
            {
                var m = booking.messages[i]; 
                let mPanel = [ <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label>{m.userFromUserId == userId ? "Hai scritto: " : "Esercente ha scritto: "}{m.text}</label>
                    </div>
                </div>
                <hr className="col-md-12"/>
            </div>];
            notesListPanels.push(mPanel);
            }
        }
        return (
            <div>

<div class="modal" id="deleteModal">
  <div class="modal-dialog">
    <div class="modal-content">

     
      <div class="modal-header">
        <h4 class="modal-title">Modal Heading</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      
      <div class="modal-body">
        Vuoi annullare l'appuntamento selezionato?
      </div>      
      <div class="modal-footer">
      <button type="button" class="btn btn-danger" onClick={this.handleDelete} data-dismiss="modal">Si, annulla Appuntamento</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">No</button>
      </div>

    </div>
  </div>
</div>

            <section className="ts-separate-bg-element" data-bg-image="/assets/img/bg-desk.jpg" data-bg-image-opacity=".1" style={{paddingTop: 6+ "rem", height: 100 + "vh"}} data-bg-color="#1b1464">
                <Navbar/>
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <form className="clearfix ts-form ts-form-email ts-inputs__transparent">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <h3 className="card-title">Dettaglio Prenotazione</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <p>Sede: <b>{booking.companyName} - {booking.companyStore.address}</b></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="datetime-local" disabled name="dateTime" value={dateTime} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label >Stato Richiesta</label>
                                            <select className="form-control" disabled name="bookingState" value={bookingState} onChange={this.handleChange} >
                                                <option value="0">In attesa</option>
                                                <option value="1">Approvata</option>
                                                <option value="2">Rifiutata</option>
                                                <option value="3">Cancellata</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div hidden className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label >Note</label>
                                            <textarea  type="text" className="form-control" rows="3" name="note" value={note} onChange={this.handleChange} placeholder="Nota" />
                                        </div>
                                    </div>
                                </div>
                                    {notesListPanels}
                                <div className="form-group clearfix">
                                    <button className="btn btn-primary float-right" hidden  type="button">Invia Nota</button>
                                    {parseInt(bookingState) != 2 && parseInt(bookingState) != 3 ? <button className="btn btn-danger float-right" data-target="#deleteModal" data-toggle="modal" type="button">Annulla Prenotazione</button> : <></> }                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
                <Footer/>
                </div>

        )
    }
}

export default AppointmentDetails;