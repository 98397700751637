import React from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import isEqual from 'lodash.isequal';
import {Helmet} from 'react-helmet';

class InputCalendar extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
          selectedDay: null,
          disabledDays: null
        };      
        this.handleDayClick = this.handleDayClick.bind(this);
    }

    componentDidMount()
    {
      const {rangeOfDays} = this.props;
      if(rangeOfDays)
      {
        
        let disabledDays = [];
        for(var i=0; i< rangeOfDays.length; i++)
        {
          var d = new Date(rangeOfDays[i].day)
          disabledDays.push(d)
        }
       this.setState({disabledDays:disabledDays});
      }
     /* disabledDays={[
        new Date(2017, 3, 12),
        new Date(2017, 3, 2),
        {
          after: new Date(2017, 3, 20),
          before: new Date(2017, 3, 25),
        },
      ]}
      selectedDays={[
                new Date(2020, 4, 12),
                new Date(2020, 4, 5),
                {
                  after: new Date(2020, 4, 20),
                  before: new Date(2020, 4, 25),
                },
              ]}
      */
    }

    componentDidUpdate(prevProps, prevState)
    {
      if(!isEqual(this.props.rangeOfDays, prevProps.rangeOfDays))
      {
        const {rangeOfDays} = this.props;
        if(rangeOfDays)
        {
          
          let disabledDays = [];
          for(var i=0; i< rangeOfDays.length; i++)
          {
            if(rangeOfDays[i].type > 0)
            {
              var d = new Date(rangeOfDays[i].day)
              disabledDays.push(d)
            }            
          }
        this.setState({disabledDays:disabledDays});
        }
      }
    }

    
  handleDayClick(day, { selected }) {
    const {disabledDays} = this.state;
    if(disabledDays && disabledDays.length > 0)
    {
      let ctrl = false;
      for(let i=0; i< disabledDays.length; i ++)
      {        
        let dd = disabledDays[i];
       if(day.getDate() === dd.getDate() && day.getMonth() === dd.getMonth())//{
        ctrl = true;
      }
      if(!ctrl)
      {
        this.setState({
          selectedDay: selected ? undefined : day,
        }); 
        if(!selected)
        {
          this.props.enableFirstStep(day)
        }
        return;
      }
    }
    else
    {
      this.setState({
        selectedDay: selected ? undefined : day,
      });
      if(!selected)
          {
            this.props.enableFirstStep(day)
          }
          return;   
    }
    this.props.enableFirstStep(null)
  }
    render() {
      const {disabledDays} = this.state; 
        return (
          <div >
            <Helmet>
        <style>{`.DayPicker-Day--disabled {
    color: #DCE0E0;
    cursor: default;
    background-color: #981616;
}`}</style>
      </Helmet>
            <DayPicker
              className="container text-white"
              initialMonth={new Date()}
              disabledDays = {disabledDays}              
              fromMonth={new Date()}
              onDayClick={this.handleDayClick}
              onMonthChange={this.props.callbackMonthChange}
            />
            <p style={{ textAlign: "center", color:"white" }}>
              {this.state.selectedDay ? this.state.selectedDay.toLocaleDateString(): 'Selezionare un giorno, tra quelli liberi.'}
            </p>
          </div>
        )
    }
}

export default InputCalendar;