import React from 'react';
import loadjs from 'loadjs';
class GenericError extends React.Component {
    componentDidMount()
    {
        loadjs(['./assets/js/custom.js'], () => {});
    }
    render() {
        return (
            <div>
                <div className="ts-background" data-bg-image-opacity=".6">
                    <div className="ts-svg ts-z-index__2">
                        <img src="/assets/svg/wave-static-02.svg"
                            className="w-100 position-absolute ts-bottom__0 ts-height__200px" alt="" />
                        <img src="/assets/svg/wave-static-01.svg"
                            className="w-100 position-absolute ts-bottom__0 ts-height__100px" alt="" />
                    </div>
                    <div className="ts-background-image ts-parallax-element" data-bg-color="#1A1462"
                        data-bg-blend-mode="multiply"></div>
                </div>
                <section id="pagenotfound" className="ts-separate-bg-element" data-bg-color="#1b1464">
                    <div className="pagenotfound">
                        <div className="pagenotfound-404">
                            <i class="fa fa-exclamation-triangle" style={{ fontSize: 150 + 'px', color: "#FF9900" }} aria-hidden="true"></i>
                        </div>
                        <h2>Siamo spiacenti, si è verificato un errore</h2>
                        <a href="/"><i class="fa fa-arrow-left" aria-hidden="true"></i> torna indietro</a>
                    </div>
                </section>
            </div>
        )
    }
}

export default GenericError;