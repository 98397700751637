import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ComingsoonPage from './components/ComingsoonPage';
import Rules from './components/Rules';
import ConfirmRegistration from './components/ConfirmRegistration';
import ChangePasswordRequestPage from './components/ChangePasswordRequestPage'; 
import ChangePasswordPage from './components/ChangePasswordPage'; 
import LoginPage from './components/LoginPage' 
import PrivacyPage from './components/PrivacyPage';
import PageNotFound from './components/PageNotFound';
import SearchPage from './components/SearchPage';
import {PrivateRoute} from './components/PrivateRoute';
import CompanyDetails from './components/CompanyDetails';
import Appointment from './components/Appointment';
import GenericError from './components/GenericError';
import AppointmentDetails from './components/AppointmentDetails';
import TableAppointment from './components/TableAppointment';
import TableCompany from './components/TableCompany';
import Settings from './components/Settings';
import {PrivateCompanyAreaRoute} from './components/PrivateRoute';

const App = () => (
    <div>
        <Switch>
            <Route exact path='/' component={SearchPage} />
            <Route exact path='/Register' component={ComingsoonPage} />
            <Route exact path='/rules' component={Rules} />
            <Route exact path='/Login' component={LoginPage} />
            <Route exact path='/confirm-email/:token' component={ConfirmRegistration} />
            <Route exact path='/Search' component={SearchPage} />            
            <PrivateRoute exact path='/Booking/New/:companyStoreIdEnc' component={Appointment} />
            <PrivateRoute exact path='/Booking/List' component={TableAppointment} />
            <PrivateRoute exact path='/Booking/Detail/:bookingIdEnc' component={AppointmentDetails} />
            <PrivateCompanyAreaRoute exact path='/Booking/History' component={TableCompany} />
            <Route exact path='/Company/Detail/:companyStoreIdEnc' component={CompanyDetails} />
            <Route exact path='/privacy' component={PrivacyPage} />
            <Route exact path='/Settings' component={Settings} />
            <Route exact path='/recovery-password-request' component={ChangePasswordRequestPage} />
            <Route exact path='/reset-password/:token' component={ChangePasswordPage} />
            <Route exact path='/404' component={PageNotFound} />
            <Route exact path='/GenericError' component={GenericError} />
            <Redirect from='*' to='/404' />
        </Switch>
    </div>
);

export default App;