import React from 'react';

class ButtonLogout extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
        };      
    }
    render() {
        return (
            <div>
                <li className="nav-item">
                <a className="nav-link"><i className="fa fa-user" ></i></a>
                </li>
            </div>
        )
    }
}

export default ButtonLogout;