import React from 'react';
import Modal from './modal';
import loadjs from 'loadjs';
import $ from 'jquery';

class SettingsStoreTab extends React.Component {
    constructor(props) {
        super(props);
        this.google = window.google;
        this.autocompleteInput = React.createRef();
        this.autocomplete = null;

        this.state = {
            Lon: '', Lat: '', Address: '',
            disabled: true,
            verifyEmail: '',
            showVerifyEmail: false,
            verifyPhoneNumber: '',
            showVerifyPhoneNumber: false,
            //table Time fields
            From_1_mon: '', To_1_mon: '', From_2_mon: '', To_2_mon: '',
            From_1_tue: '', To_1_tue: '', From_2_tue: '', To_2_tue: '',
            From_1_wed: '', To_1_wed: '', From_2_wed: '', To_2_wed: '',
            From_1_thu: '', To_1_thu: '', From_2_thu: '', To_2_thu: '',
            From_1_fri: '', To_1_fri: '', From_2_fri: '', To_2_fri: '',
            From_1_sat: '', To_1_sat: '', From_2_sat: '', To_2_sat: '',
            From_1_sun: '', To_1_sun: '', From_2_sun: '', To_2_sun: '',
            timeDisabled: true,

            IsClosingDayMon: false,
            IsClosingDayTue: false,
            IsClosingDayWed: false,
            IsClosingDayThu: false,
            IsClosingDayFri: false,
            IsClosingDaySat: false,
            IsClosingDaySun: false

        };
        //Field onChange
        this.handleChange = this.handleChange.bind(this);
        this.handleFieldTime = this.handleFieldTime.bind(this);
        //Fields onClick
        this.handleClickFields = this.handleClickFields.bind(this);
        this.handleClickTimeFields = this.handleClickTimeFields.bind(this);
        this.handleClickFieldsCancel = this.handleClickFieldsCancel.bind(this);
        this.handleClickTimeFieldsCancel = this.handleClickTimeFieldsCancel.bind(this);

        this.handleClickEmail = this.handleClickEmail.bind(this);
        this.handleClickEmailCancel = this.handleClickEmailCancel.bind(this);

        this.handleClickPhoneNumber = this.handleClickPhoneNumber.bind(this);
        this.handleClickPhoneNumberCancel = this.handleClickPhoneNumberCancel.bind(this);
                //Table CheckBox
                this.handleCheckWeekly = this.handleCheckWeekly.bind(this);
    }
    componentDidMount() {
        loadjs(['./assets/js/custom.js'], () => { });
        //Autocoplete address
        this.autocomplete = new this.google.maps.places.Autocomplete(this.autocompleteInput.current,
            { types: ['geocode'], componentRestrictions: { country: "it" } });
        this.autocomplete.addListener('place_changed', this.handleChangecompanyAddresses);
    }

    handleChange(event) {
        const { name, value } = event.target
        this.setState({ [name]: value });
    }

    //GET latitude and longitude from GOOGLE MAPS
    handleChangecompanyAddresses() {
        const place = this.autocomplete.getPlace();
        const lan = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        this.setState({ Lon: lng });
        this.setState({ Lat: lan });
        this.setState({ Address: place.formatted_address })
    }

    //verify Address
    handleClickFields(event) {
        this.setState({ disabled: false })       
        const { disabled, Address, Lat, Lon } = this.state;
        if (!disabled) {
            console.log(Address, Lat, Lon)
        }
    }
    handleClickFieldsCancel(event) { this.setState({ disabled: true }) }

    //Table fields input Time
    handleFieldTime(event) {
        if (event.target.name === 'CompanyWeekdays[0].From_1') this.setState({ From_1_mon: event.target.value });
        if (event.target.name === 'CompanyWeekdays[0].To_1') this.setState({ To_1_mon: event.target.value });
        if (event.target.name === 'CompanyWeekdays[0].From_2') this.setState({ From_2_mon: event.target.value });
        if (event.target.name === 'CompanyWeekdays[0].To_2') this.setState({ To_2_mon: event.target.value });

        if (event.target.name === 'CompanyWeekdays[1].From_1') this.setState({ From_1_tue: event.target.value });
        if (event.target.name === 'CompanyWeekdays[1].To_1') this.setState({ To_1_tue: event.target.value });
        if (event.target.name === 'CompanyWeekdays[1].From_2') this.setState({ From_2_tue: event.target.value });
        if (event.target.name === 'CompanyWeekdays[1].To_2') this.setState({ To_2_tue: event.target.value });

        if (event.target.name === 'CompanyWeekdays[2].From_1') this.setState({ From_1_wed: event.target.value });
        if (event.target.name === 'CompanyWeekdays[2].To_1') this.setState({ To_1_wed: event.target.value });
        if (event.target.name === 'CompanyWeekdays[2].From_2') this.setState({ From_2_wed: event.target.value });
        if (event.target.name === 'CompanyWeekdays[2].To_2') this.setState({ To_2_wed: event.target.value });

        if (event.target.name === 'CompanyWeekdays[3].From_1') this.setState({ From_1_thu: event.target.value });
        if (event.target.name === 'CompanyWeekdays[3].To_1') this.setState({ To_1_thu: event.target.value });
        if (event.target.name === 'CompanyWeekdays[3].From_2') this.setState({ From_2_thu: event.target.value });
        if (event.target.name === 'CompanyWeekdays[3].To_2') this.setState({ To_2_thu: event.target.value });

        if (event.target.name === 'CompanyWeekdays[4].From_1') this.setState({ From_1_fri: event.target.value });
        if (event.target.name === 'CompanyWeekdays[4].To_1') this.setState({ To_1_fri: event.target.value });
        if (event.target.name === 'CompanyWeekdays[4].From_2') this.setState({ From_2_fri: event.target.value });
        if (event.target.name === 'CompanyWeekdays[4].To_2') this.setState({ To_2_fri: event.target.value });

        if (event.target.name === 'CompanyWeekdays[5].From_1') this.setState({ From_1_sat: event.target.value });
        if (event.target.name === 'CompanyWeekdays[5].To_1') this.setState({ To_1_sat: event.target.value });
        if (event.target.name === 'CompanyWeekdays[5].From_2') this.setState({ From_2_sat: event.target.value });
        if (event.target.name === 'CompanyWeekdays[5].To_2') this.setState({ To_2_sat: event.target.value });

        if (event.target.name === 'CompanyWeekdays[6].From_1') this.setState({ From_1_sun: event.target.value });
        if (event.target.name === 'CompanyWeekdays[6].To_1') this.setState({ To_1_sun: event.target.value });
        if (event.target.name === 'CompanyWeekdays[6].From_2') this.setState({ From_2_sun: event.target.value });
        if (event.target.name === 'CompanyWeekdays[6].To_2') this.setState({ To_2_sun: event.target.value });
    }

    // Table Checkbox 
    handleCheckWeekly(event){
        if(event.target.name === 'CompanyWeekdays[0].IsClosingDay') {
            this.setState({ IsClosingDayMon: true, From_1_mon: '', To_1_mon: '', From_2_mon: '', To_2_mon: ''});
            const { IsClosingDayMon } = this.state
            if(IsClosingDayMon){
                this.setState({ IsClosingDayMon: false})
            }
        }
        if(event.target.name === 'CompanyWeekdays[1].IsClosingDay') {
            this.setState({ IsClosingDayTue: true, From_1_tue: '', To_1_tue: '', From_2_tue: '', To_2_tue: ''});
            const { IsClosingDayTue } = this.state
            if(IsClosingDayTue){
                this.setState({ IsClosingDayTue: false})
            }
        }
        if(event.target.name === 'CompanyWeekdays[2].IsClosingDay') {
            this.setState({ IsClosingDayWed: true, From_1_wed: '', To_1_wed: '', From_2_wed: '', To_2_wed: ''});
            const { IsClosingDayWed } = this.state;
            if(IsClosingDayWed){this.setState({ IsClosingDayWed: false})}
        }
        if(event.target.name === 'CompanyWeekdays[3].IsClosingDay') {
            this.setState({ IsClosingDayThu: true, From_1_thu: '', To_1_thu: '', From_2_thu: '', To_2_thu: ''});
            const { IsClosingDayThu } = this.state;
            if(IsClosingDayThu){this.setState({ IsClosingDayThu: false})}
        }
        if(event.target.name === 'CompanyWeekdays[4].IsClosingDay') {
            this.setState({ IsClosingDayFri: true, From_1_fri: '', To_1_fri: '', From_2_fri: '', To_2_fri: ''});
            const { IsClosingDayFri } = this.state;
            if(IsClosingDayFri){this.setState({ IsClosingDayFri: false})}
        }
        if(event.target.name === 'CompanyWeekdays[5].IsClosingDay') {
            this.setState({ IsClosingDaySat: true, From_1_sat: '', To_1_sat: '', From_2_sat: '', To_2_sat: ''});
            const { IsClosingDaySat } = this.state;
            if(IsClosingDaySat){this.setState({ IsClosingDaySat: false})}
        }
        if(event.target.name === 'CompanyWeekdays[6].IsClosingDay') {
            this.setState({ IsClosingDaySun: true,From_1_sun : '', To_1_sun: '', From_2_sun: '', To_2_sun: ''});
            const { IsClosingDaySun } = this.state;
            if(IsClosingDaySun){this.setState({ IsClosingDaySun: false})}
        }
    }

    //verify TimeTable
    handleClickTimeFields(event) {
        this.setState({ timeDisabled: false })       
        const { timeDisabled } = this.state;
        if (!timeDisabled) {
           
        }
    }
    handleClickTimeFieldsCancel(event) { this.setState({ timeDisabled: true }) }

    //Verify Eamil
    handleClickEmail(event) {
        this.setState({ showVerifyEmail: true });
        const { showVerifyEmail, verifyEmail } = this.state
        console.log(showVerifyEmail)
        if (showVerifyEmail) {
            if (this.isRequiredErrorFieldInput(verifyEmail, "Verifica Email")) {
                const email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (email.test(verifyEmail)) {
                    if (verifyEmail === /*current Email from API*/ 'email@email.it') {

                    }
                } else this.ModalError(`Assicurati che Email sia corretto.`);
            } else this.ModalError(`Assicurati che Email corrispondano.`);
        }
    }
    handleClickEmailCancel(event) { this.setState({ showVerifyEmail: false }); }

    //Verify Phonenumber
    handleClickPhoneNumber(event) {
        this.setState({ showVerifyPhoneNumber: true });
        const { showVerifyPhoneNumber, verifyPhoneNumber } = this.state
        if (showVerifyPhoneNumber) {
            if (this.isRequiredErrorFieldInput(verifyPhoneNumber, "Verifica Numero di telefono")) {
                var number = /^[0-9]+$/;
                if (verifyPhoneNumber === /*current PhoneNumber from API*/ '123') {
                    if (number.test(verifyPhoneNumber)) {

                    } else this.ModalError(`Assicurati che il numero di telefono sia corretto.`);
                } else this.ModalError(`Assicurati che il numero di telefono corrispondano.`);
            }
        }
    }
    handleClickPhoneNumberCancel(event) { this.setState({ showVerifyPhoneNumber: false }); }

    //ModalError
    ModalError(nameField) {
        this.setState({
            classtype: 'modal-error',
            icon: 'fa fa-close',
            description: nameField
        }, () => {
            window.$('#fielderrorChangePsw').modal('show');
        });
    }

    //Input Modal Error
    isRequiredErrorFieldInput(value, nameField) {
        var status_ok = false;
        if (value !== "") status_ok = true;
        else this.ModalError(`il Campo ${nameField} è obbligatorio.`);
        return status_ok
    }

    render() {
        const { showVerifyEmail, showVerifyPhoneNumber, disabled, timeDisabled } = this.state;
        const { IsClosingDayMon, IsClosingDayTue, IsClosingDayWed, IsClosingDayThu, IsClosingDayFri, IsClosingDaySat, IsClosingDaySun } = this.state;
        const {
            From_1_mon, To_1_mon, From_2_mon, To_2_mon,
            From_1_tue, To_1_tue, From_2_tue, To_2_tue,
            From_1_wed, To_1_wed, From_2_wed, To_2_wed,
            From_1_thu, To_1_thu, From_2_thu, To_2_thu,
            From_1_fri, To_1_fri, From_2_fri, To_2_fri,
            From_1_sat, To_1_sat, From_2_sat, To_2_sat,
            From_1_sun, To_1_sun, From_2_sun, To_2_sun
        } = this.state;

        const modifyEmail = (
            <div className="row" >
                <div className="col">
                    <div className="form-group">
                        <label >Verifica Email</label>
                        <input type="email" className="form-control" name="verifyEmail" onChange={this.handleChange} placeholder="Verifica Email" />
                    </div>
                </div>
            </div>
        )
        const modifyPhoneNumber = (
            <div className="row" >
                <div className="col">
                    <div className="form-group">
                        <label >Verifica Numero di Telefono</label>
                        <input className="form-control" type="tel" name="verifyPhoneNumber" onChange={this.handleChange} placeholder="Verifica Numero di Telefono" />
                    </div>
                </div>
            </div>
        )
        return (
            <div className="container pt-4" >
                <Modal id="fielderrorChangePsw" classtype={this.state.classtype} icon={this.state.icon} title={this.state.title} description={this.state.description} />
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label >Indirizzo</label>
                            <input type="text" ref={this.autocompleteInput} className="form-control" name="Address" placeholder="Indirizzo" disabled={disabled ? 'disabled' : ''} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group clearfix" >
                            {disabled ? null : <button className="btn btn-secondary float-right ml-2" onClick={this.handleClickFieldsCancel} >Annulla</button>}
                            <button className="btn btn-primary float-right" onClick={this.handleClickFields} >{!disabled ? 'Salva' : 'Modifica'}</button>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr style={{ textAlign: "center" }}>
                                <th scope="col"></th>
                                <th scope="col">Apertura</th>
                                <th scope="col">Chiusura</th>
                                <th scope="col">Apertura</th>
                                <th scope="col">Chiusura</th>
                                <th scope="col"><i className="fa fa-calendar-times-o" style={{ fontSize: 20 + "px", cursor: "pointer" }} data-toggle="modal" onClick={this.handleOnClickClosingDayInfo} title="Giorni di chiusura"></i></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="mon">
                                <th scope="row" style={{ verticalAlign: "middle" }}>Lun</th>
                                <td hidden><input hidden name="CompanyWeekdays[0].WeekDay" value="0" readOnly={true} /></td>
                                <td><input type="time" name="CompanyWeekdays[0].From_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayMon ? '' : From_1_mon} disabled={timeDisabled ? 'disabled' : ''} /></td>
                                <td><input type="time" name="CompanyWeekdays[0].To_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayMon ? '' : To_1_mon} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[0].From_2"  min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayMon ? '' : From_2_mon} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[0].To_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayMon ? '' : To_2_mon} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td style={{ verticalAlign: "middle", textAlign: "center" }} ><input type="checkbox" name="CompanyWeekdays[0].IsClosingDay" onChange={this.handleCheckWeekly} /></td>
                            </tr>
                            <tr className="tue">
                                <th scope="row" style={{ verticalAlign: "middle" }}>Mar</th>
                                <td hidden><input hidden name="CompanyWeekdays[1].WeekDay" value="1" readOnly={true} /></td>
                                <td><input type="time" name="CompanyWeekdays[1].From_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayTue ? '' : From_1_tue} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[1].To_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayTue ? '' : To_1_tue} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[1].From_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayTue ? '' : From_2_tue} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[1].To_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayTue ? '' : To_2_tue} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td style={{ verticalAlign: "middle", textAlign: "center" }}><input type="checkbox" name="CompanyWeekdays[1].IsClosingDay" onChange={this.handleCheckWeekly} /></td>
                            </tr>
                            <tr className="wed">
                                <th scope="row" style={{ verticalAlign: "middle" }}>Mer</th>
                                <td hidden><input hidden name="CompanyWeekdays[2].WeekDay" value="2" readOnly={true} /></td>
                                <td><input type="time" name="CompanyWeekdays[2].From_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayWed ? '' : From_1_wed} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[2].To_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayWed ? '' : To_1_wed} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[2].From_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayWed ? '' : From_2_wed} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[2].To_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayWed ? '' : To_2_wed} disabled={timeDisabled ? 'disabled' : ''} /></td>
                                <td style={{ verticalAlign: "middle", textAlign: "center" }}><input type="checkbox" name="CompanyWeekdays[2].IsClosingDay" onChange={this.handleCheckWeekly} /></td>
                            </tr>
                            <tr className="thu">
                                <th scope="row" style={{ verticalAlign: "middle" }}>Gio</th>
                                <td hidden><input hidden name="CompanyWeekdays[3].WeekDay" value="3" readOnly={true} /></td>
                                <td><input type="time" name="CompanyWeekdays[3].From_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayThu ? '' : From_1_thu} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[3].To_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayThu ? '' : To_1_thu} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[3].From_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayThu ? '' : From_2_thu} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[3].To_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayThu ? '' : To_2_thu} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td style={{ verticalAlign: "middle", textAlign: "center" }}><input type="checkbox" name="CompanyWeekdays[3].IsClosingDay" onChange={this.handleCheckWeekly} /></td>
                            </tr>
                            <tr className="fri">
                                <th scope="row" style={{ verticalAlign: "middle" }}>Ven</th>
                                <td hidden><input hidden name="CompanyWeekdays[4].WeekDay" value="4" readOnly={true} /></td>
                                <td><input type="time" name="CompanyWeekdays[4].From_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayFri ? '' : From_1_fri} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[4].To_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayFri ? '' : To_1_fri} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[4].From_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayFri ? '' : From_2_fri} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[4].To_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDayFri ? '' : To_2_fri} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td style={{ verticalAlign: "middle_fri", textAlign: "center" }}><input type="checkbox" name="CompanyWeekdays[4].IsClosingDay" onChange={this.handleCheckWeekly} /></td>
                            </tr>
                            <tr className="sat">
                                <th scope="row" style={{ verticalAlign: "middle" }}>Sab</th>
                                <td hidden><input hidden name="CompanyWeekdays[5].WeekDay" value="5" readOnly={true} /></td>
                                <td><input type="time" name="CompanyWeekdays[5].From_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDaySat ? '' : From_1_sat} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[5].To_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDaySat ? '' : To_1_sat} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[5].From_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDaySat ? '' : From_2_sat} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[5].To_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDaySat ? '' : To_2_sat} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td style={{ verticalAlign: "middle", textAlign: "center" }}><input type="checkbox" name="CompanyWeekdays[5].IsClosingDay" onChange={this.handleCheckWeekly} /></td>
                            </tr>
                            <tr className="sun">
                                <th scope="row" style={{ verticalAlign: "middle" }}>Dom</th>
                                <td hidden><input hidden name="CompanyWeekdays[6].WeekDay" value="6" readOnly={true} /></td>
                                <td><input type="time" name="CompanyWeekdays[6].From_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDaySun ? '' : From_1_sun} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[6].To_1" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDaySun ? '' : From_1_sun} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[6].From_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDaySun ? '' : From_1_sun} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td><input type="time" name="CompanyWeekdays[6].To_2" min="00:00" max="23:59" onChange={this.handleFieldTime} className="form-control" value={IsClosingDaySun ? '' : From_1_sun} disabled={timeDisabled ? 'disabled' : ''}/></td>
                                <td style={{ verticalAlign: "middle", textAlign: "center" }}><input type="checkbox" name="CompanyWeekdays[6].IsClosingDay" onChange={this.handleCheckWeekly} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group" >
                            {timeDisabled ? null : <button className="btn btn-secondary float-right ml-2" onClick={this.handleClickTimeFieldsCancel} >Annulla</button>}
                            <button className="btn btn-primary float-right" onClick={this.handleClickTimeFields} >{!timeDisabled ? 'Salva' : 'Modifica'}</button>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md col-sm">
                        <div className="form-group">
                            <label >Email</label>
                            <input type="email" className="form-control" name="Email" placeholder="Email" disabled />
                        </div>
                    </div>
                </div>
                {showVerifyEmail ? modifyEmail : null}
                <div className="row">
                    <div className="col">
                        <div className="form-group" >
                            {showVerifyEmail ? <button className="btn btn-secondary float-right ml-2" onClick={this.handleClickEmailCancel} >Annulla</button> : null}
                            <button className="btn btn-primary float-right" id="btn-modif-email" onClick={this.handleClickEmail} >{showVerifyEmail ? 'Salva' : 'Modifica'}</button>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label >Telefono</label>
                            <input className="form-control" type="tel" id="phonenumber" name="PhoneNumber" placeholder="Numero di Telefono" disabled />
                        </div>
                    </div>
                </div>
                {showVerifyPhoneNumber ? modifyPhoneNumber : null}
                <div className="row">
                    <div className="col">
                        <div className="form-group clearfix" >
                            {showVerifyPhoneNumber ? <button className="btn btn-secondary float-right ml-2" onClick={this.handleClickPhoneNumberCancel} >Annulla</button> : null}
                            <button className="btn btn-primary float-right" onClick={this.handleClickPhoneNumber} >{showVerifyPhoneNumber ? 'Salva' : 'Modifica'}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SettingsStoreTab;