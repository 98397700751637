import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import SettingsUserTab from './SettingsUserTab'
import SettingsCompanyTab from './SettingsCompanyTab'
import SettingsStoreTab from './SettingsStoreTab'

class Settings extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
        };      
    }
    render() {
        return (
            <div>
                <section className="ts-separate-bg-element" data-bg-image="/assets/img/bg-desk.jpg" data-bg-image-opacity=".1" style={{ paddingTop: 2 + "rem", minHeight: 100 + "vh" }} data-bg-color="#1b1464">
                    <Navbar />
                    <div className="container" style={{marginTop: 80+"px"}}>
                        <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist" style={{backgroundColor: "#ffff"}}>
                            <li className="nav-item">
                                <a className="nav-link active" id="profil-tab" data-toggle="tab" href="#profil" role="tab" aria-controls="profil" aria-selected="true">Profilo</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="company-tab" data-toggle="tab" href="#company" role="tab" aria-controls="company" aria-selected="false">Azienda</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="store-tab" data-toggle="tab" href="#store" role="tab" aria-controls="store" aria-selected="false">Sede</a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane  show active" id="profil" role="tabpanel" aria-labelledby="profil-tab" style={{backgroundColor: "#fff"}}>
                                <SettingsUserTab />
                            </div>
                            <div className="tab-pane " id="company" role="tabpanel" aria-labelledby="company-tab" style={{backgroundColor: "#fff"}}>
                                <SettingsCompanyTab/>
                            </div>
                            <div className="tab-pane fade" id="store" role="tabpanel" aria-labelledby="store-tab" style={{ backgroundColor: "#fff" }}>
                                <SettingsStoreTab />
                            </div>
                        </div>
                    </div>
                    <Footer />
                </section>
            </div>
        )
    }
}

export default Settings;