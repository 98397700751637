import React from 'react';
import CompanyForm from './CompanyRegisterForm'
import Userform from './UserRegisterForm'
import loadjs from 'loadjs';

class ComingsoonPage extends React.Component {
constructor(props)
{
    super(props);
    this.showFormAzienda = this.showFormAzienda.bind(this);
    this.showFormCliente = this.showFormCliente.bind(this);
    this.scrollToRegisterForm = this.scrollToRegisterForm.bind(this);
}
    componentDidMount()
    {
        loadjs(['./assets/js/custom.js'], () => {});
    }
    scrollToRegisterForm = () => {
        this.registerFormSection.scrollIntoView({ behavior: "smooth" });
      }

    showFormCliente(e) {
        if (document.getElementById("formCliente").style.display === "none") {
            document.getElementById("formCliente").style.display = "block";
            document.getElementById("formAzienda").style.display = "none";
        } else {
            document.getElementById("formCliente").style.display = "none";
        }
        this.scrollToRegisterForm();
    }

    showFormAzienda(e) {
        if (document.getElementById("formAzienda").style.display === "none") {
            document.getElementById("formAzienda").style.display = "block";
            document.getElementById("formCliente").style.display = "none";
        } else {
            document.getElementById("formAzienda").style.display = "none";
        }
        this.scrollToRegisterForm();
    }
    render() {
      
        return (
            <div className="ts-page-wrapper" id="page-top">
                <header id="ts-hero">
                    <nav className="navbar navbar-expand-lg navbar-dark fixed-top ts-separate-bg-element" data-bg-color="#1a1360">
                        <div className="container">
                            <a className="navbar-brand" href="#page-top">
                                <img src="/assets/img/2000w-white.png" width="200px" height="45px" alt="" />
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                                <div className="navbar-nav ml-auto">
                                    <a className="nav-item nav-link ts-scroll btn btn-primary btn-sm text-white ml-3 px-3 ts-width__auto"
                                        href="/login"><span>Accedi</span></a>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
                <section className="parent-centre"  style={{ minHeight: "100vh" }}>
                    <div className="child-centre">

                    
                <div className="container align-self-center">
                        <div className="row align-items-center">
                            <div className="col-sm-7" style={{ textAlign: "center" }}>
                                <h3 className="ts-opacity__50 white" style={{ color: "white" }}><strong>PRE</strong>nota <strong>SERV</strong>izi e
                            <strong> APP</strong>untamenti!</h3>
                                <h1 style={{ color: "white" }}>REGISTRATI!</h1>
                                <div className="row">
                                    <div className="col-sm-6 col-md-4 col-xl-4">
                                        <figure data-animate="ts-fadeInUp">
                                            <figure className="icon mb-5 p-2">
                                                <img src="/assets/img/icon-ricerca.png" alt="" />
                                                <div className="ts-svg ts-svg__organic-shape-01" data-animate="ts-zoomInShort"></div>
                                            </figure>
                                            <h3 style={{ color: "white" }}>Ricerca il servizio</h3>
                                            <p style={{ color: 'white', fontSize: 'large' }}>
                                                Cerca il servizio di cui hai bisogno e contatta l'esercente per prenotare un appuntamento
                                            </p>
                                        </figure>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-xl-4">
                                        <figure data-animate="ts-fadeInUp" data-delay="0.1s">
                                            <figure className="icon mb-5 p-2">
                                                <img src="/assets/img/icon-tick.png" alt="" />
                                                <div className="ts-svg ts-svg__organic-shape-02" data-animate="ts-zoomInShort"></div>
                                            </figure>
                                            <h3 style={{ color: "white" }}>Attendi la conferma</h3>
                                            <p style={{ color: 'white', fontSize: 'large' }}>
                                                Una volta richiesto l'appuntamento attendi la conferma con l'orario preciso
                                            </p>
                                        </figure>
                                    </div>
                                    <div className="col-sm-6 offset-sm-4 col-md-4 offset-md-0 col-xl-4">
                                        <figure data-animate="ts-fadeInUp" data-delay="0.2s">
                                            <figure className="icon mb-5 p-2">
                                                <img src="/assets/img/icon-vai.png" alt="" />
                                                <div className="ts-svg ts-svg__organic-shape-03" data-animate="ts-zoomInShort"></div>
                                            </figure>
                                            <h3 style={{ color: "white" }}>Vai!</h3>
                                            <p style={{ color: 'white', fontSize: 'large' }}>
                                                Non resta che presentarti all'appuntamento all'orario stabilito senza problemi di file e di assembramenti
                                            </p>
                                        </figure>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 mb-3">                                        
                                            <button className="btn btn-light btn-lg" onClick={this.showFormCliente}>Registrazione
                                        cliente</button>
                                    </div>
                                    <div className="col-sm-6 mb-5">                                        
                                            <button className="btn btn-light btn-lg" onClick={this.showFormAzienda}>Registrazione
                                            azienda</button>                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-5 d-none d-sm-block">
                                <div className="owl-carousel text-center" data-owl-nav="1" data-owl-loop="1">
                                    <img src="/assets/img/img-phone-1st-screen.png" className="d-inline-block mw-100 p-md-5 p-lg-0 ts-width__auto" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                <div id="ts-dynamic-waves" className="ts-background" data-bg-color="#1a1462">
                        <svg className="ts-svg ts-parallax-element" width="100%" height="100%" version="1.1"
                            xmlns="http://www.w3.org/2000/svg">
                            <defs></defs>
                            <path className="ts-dynamic-wave" d="" data-wave-color="#dd4b5b" data-wave-height=".6"
                                data-wave-bones="2" data-wave-speed="0.15" />
                        </svg>
                        <svg className="ts-svg" width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <defs></defs>
                            <path className="ts-dynamic-wave" d="" data-wave-color="#dd4b5b" data-wave-height=".0" data-wave-bones="1"
                                data-wave-speed="0.20" />
                        </svg>
                    </div>
                    </div>
                </section>
                <footer ref={(el) => { this.registerFormSection = el; }} id="ts-footer">
                    <div  id="formAzienda" style={{ display: 'none' }}>
                        <img src="/assets/img/registrazione-azienda.png" alt="" width="100%" height="100%" />
                        <div>
                            <CompanyForm />
                        </div>
                    </div>
                    <div id="formCliente" style={{ display: 'none' }}>
                        <img src="/assets/img/registrazione-cliente.png" width="100%" alt="" height="100%"></img>
                        <div>
                            <Userform />
                        </div>
                    </div>
                </footer>
                <div className="text-center text-white py-4" id="Test" style={{ backgroundColor: "#2C266E" }} >
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-4"><small><a href="user-rule">Regole d'uso per l'utente</a></small></div>
                                <div className="col-md-4"><small><a href="privacy">Privacy Policy</a></small></div>
                                <div className="col-md-4"><small><a href="company-rule">Regole d'uso per l'azienda</a></small></div>
                            </div>
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                    <small>© 2020 Reindal, Tutti i diritti riservati</small>
                </div>
            </div>);
    }
}

export default ComingsoonPage;