import React from 'react';
import Modal from './modal';
import Navbar from './Navbar';
import Footer from './Footer';
import loadjs from 'loadjs';
import { userService } from '../services/user.service';

class ChangePasswordRequestPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: '',
    };
    //Field onChange
    this.handleChange = this.handleChange.bind(this);
    //Submit
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount()
  {
      loadjs(['./assets/js/custom.js'], () => {});
  }

  handleChange(event) {
    this.setState({Email: event.target.value});
  }

  //ModalError
  ModalError(nameField) {
    this.setState({
      classtype: 'modal-error',
      icon: 'fa fa-close',
      description: nameField
    }, () => {
      window.$('#fielderrorRecoveryRequest').modal('show');
    });
  }

  //Input Modal Error
  isRequiredErrorFieldInput(value, nameField) {
    var status_ok = false;
    if (value !== "") status_ok = true;
    else this.ModalError(`il Campo ${nameField} è obbligatorio.`);
    return status_ok
  }

  handleSubmit(event) {
    event.preventDefault();
    const { Email } = this.state
    if (this.isRequiredErrorFieldInput(Email, "Email ")) {
        userService.ChangePasswordRequest(Email).then(
            data => {
                if(data.ok){
                    window.$("#recoveryRequestModelSuccess").modal("show");
                }
                console.log(data)
            },
            error => {
            }
        )
    }
  }
  
  render() {
  return (
    <div>
      <section className="ts-separate-bg-element" data-bg-color="#1b1464" style={{height:100+"vh"}}>
        <Navbar/>
        <Modal id="fielderrorRecoveryRequest" classtype={this.state.classtype} icon={this.state.icon} title={this.state.title} description={this.state.description}  />
        <Modal id="recoveryRequestModelSuccess" classtype='modal-success' icon='fa fa-check' title='' description='Richiesta di cambio password Inviato con successo!' />
        <div className="container d-flex justify-content-center" style={{paddingTop:25+"vh"}}>
            <div className="ts-box m-0 ts-mt__n-10" style={{width:100+"vh"}}>
                <div className="row">
                    <div className="col-md-12">
                        <h3>Richiesta cambio password</h3>
                        <form id="form-contact" onSubmit={this.handleSubmit} className="clearfix ts-form ts-form-email ts-inputs__transparent">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Email *</label>
                                        <input className="form-control" type="email" name="Email" onChange={this.handleChange} placeholder="Email" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-primary float-right" >Invia</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
      </section>
  </div>
  );
}
}

export default ChangePasswordRequestPage;
