import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import loadjs from 'loadjs';
class UserRole extends React.Component {
    componentDidMount()
    {
        loadjs(['./assets/js/custom.js'], () => {});
    }
    render() {
      
        return (
            <div className="ts-page-wrapper" id="page-top">
                <Navbar/>
            <header id="ts-hero" className="d-block py-5 ts-separate-bg-element" data-bg-blend-mode="multiply" data-bg-parallax="scroll" data-bg-parallax-speed="3" data-bg-particles-line-color="rgba(255,255,255,.6)" data-bg-particles-dot-color="rgba(255,255,255,.1)" data-bg-particles-parallax="1">
                <div className="text-center p-5">
                    <a href="comingsoon.html.html">
                        <img src="/assets/img/preservapp-logo-white.png" alt=""/>
                    </a>
                </div>
                <div className="container align-self-center py-5">
                    <div className="align-items-center text-center py-3">
                        <h1>REGOLE D'USO</h1>
                        <div className="text-center py-5">
                        </div>
                    </div>
                </div>
                <div className="ts-background" data-bg-image-opacity=".6">
                    <div className="ts-svg ts-z-index__2">
                        <img src="/assets/svg/wave-static-02.svg" className="w-100 position-absolute ts-bottom__0 ts-height__200px" alt="" />
                        <img src="/assets/svg/wave-static-01.svg" className="w-100 position-absolute ts-bottom__0 ts-height__100px" alt="" />
                    </div>
                    <div className="ts-background-image ts-parallax-element" data-bg-color="#1A1462"  data-bg-blend-mode="multiply"></div>
                </div>
            </header>
            <main id="ts-content">
                <section id="demos" className="ts-block text-center">
                    <div className="container">
                        <div className="ts-title">
                        <div className="float-right"><a style={{fontSize: 20+"px"}} href="/"> indietro</a> </div>
                            <h3>Regole d'uso</h3>
                            <object data="/assets/pdf/terms_and_conditions_IT.pdf#toolbar=0&navpanes=0&scrollbar=0&zoom=130"
                                type="text/plain" width="100%" style={{height: 1600+"px"}}>
                            </object>
                            <script id="CookieDeclaration"
                                src="https://consent.cookiebot.com/c8f20037-178e-47bc-9b80-4648155b28b4/cd.js"
                                type="text/javascript" async></script>
                        </div>
                    </div>
                </section>
            </main>
            <Footer/>
        </div>
        )

    }
}

export default UserRole;