import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import Modal from './modal';
import Navbar from './Navbar';
import Footer from './Footer';
import { userService } from '../services/user.service';
import loadjs from 'loadjs';



class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        email: '',
        password: '',
        submitted: false,
        loading: false,
        error: '',
        returnUrl: ''
    };
    //Field onChange
    this.handleChange = this.handleChange.bind(this);
    //Submit
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount()
  {
    loadjs(['./assets/js/custom.js'], () => {});
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
}

 

    handleSubmit(event) {
        //event.preventDefault();
        //alert("submit")
        //console.log(this.props.location.state)
        const { email, password, returnUrl } = this.state;        
        if (email && email.length > 0) {
            if (password && password.length > 0) {
                console.log(email + " "+ password)
                userService.login(email, password).then(
                    user => {
                        //const { from } = this.props.location.state || { from: { pathname: "/Search" } };
                        this.props.history.push("/Search");
                    },
                    error => {window.$("#loginModalError").modal("show");return false;}//this.setState({ error, loading: false })
                );
            }
        }
        else{window.$("#loginModalError").modal("show");return false;}
    }
  
  render() {
    const { email, password, submitted, loading, error } = this.state; 
  return (
    <div>
      <section className="ts-separate-bg-element" data-bg-color="#1b1464" style={{height:100+"vh"}}>
        <Navbar/>
        <Modal id="loginModalError" classtype='modal-error' icon='fa fa-close' title='Errore!' description='Email o password non corretti, riprovare!' />     
        <div className="container d-flex justify-content-center" style={{paddingTop:20+"vh"}}>
            <div className="ts-box mb-0 p-5 ts-mt__n-10" style={{width:100+"vh"}}>
                <div className="row">
                    <div className="col-md-12">
                        <h3>Accedi</h3>
                        <form id="form-contact" className="clearfix ts-form ts-form-email ts-inputs__transparent">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Email *</label>
                                        <input name="email" className="form-control" id="form-contact-email" type="email" value={this.state.email} onChange={this.handleChange} placeholder="Email" required/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label >Password *</label>
                                        <input className="form-control" name="password" id="form-contact-password" type="password" value={this.state.password} onChange={this.handleChange} placeholder="Password" required/>
                                    </div>
                                </div>
                            </div>
                            <a href="/recovery-password-request">Hai dimenticato la password?</a>
                            <div className="form-group">
                                <button type="button" onClick={this.handleSubmit} className="btn btn-primary float-right">Entra</button>
                            </div>
                            <div className="form-contact-status"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
      <Footer/>
      </section>
  </div>
  );
}
}

export default LoginPage;
