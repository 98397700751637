import React from 'react';
import { userService } from '../services/user.service';
class ChatModal extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            id: '',
            bookingidEnc: '',
            messages : [],
            currentUserId: 0,
            message: '',
            disableSend:false
        };      
        this.handleChange = this.handleChange.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
    }
    componentDidMount(){
        this.updateComponent();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.id !== prevProps.id || this.props.bookingidEnc !== prevProps.bookingidEnc ) {
           this.updateComponent();
        }
    }

    updateComponent()
    {
        let user = JSON.parse(localStorage.getItem('user'));
        this.setState({ 
            id: this.props.id,
            bookingidEnc: this.props.bookingidEnc,
            currentUserId: user.userId,
            disableSend: true
        });
        var idEnc = this.props.bookingidEnc;
        if(idEnc && idEnc != '')
        {
            userService.GetMessagesList(idEnc).then(
                data => {
                    if(data && data.ok)
                    {
                        this.setState({ 
                            messages: data.data,
                            disableSend: false
                        });
                    }                
                },
            error => {this.setState({ disableSend: false });}
            );
        }
    }
    handleChange(event)
    {
        const { name, value } = event.target;
        this.setState({[name]: value});
    }
    sendMessage(event)
    {
        this.setState({ disableSend: true });
        var idEnc = this.props.bookingidEnc;
        const { message } = this.state;
        userService.SendMessage(idEnc, message).then(
            data => {
                if(data && data.ok)
                {
                    this.updateComponent();
                }                
            },
        error => {this.setState({ disableSend: false });}
        );
    }

    render(){
        const {messages, currentUserId, disableSend} = this.state;
        let messagesPanels = [];
        if(messages && messages.length > 0)
        {
            console.log(1)
            for(var i=0; i < messages.length;i++)
            {
                var m = messages[i];
                if(currentUserId == m.userFromUserId)
                {
                    messagesPanels.push(<>
                        <div style={{ textAlign: "right" }} className="mb-1">
                                    <span style={{ color: "gray" }} >{m.userFrom ? m.userFrom.firstName + " " +m.userFrom.lastName : ''}</span>
                                </div>
                                <div className="d-flex justify-content-end mb-4">
                                    <div className="msg_cotainer_right">
                                        {m.text}
									<span hidden className="msg_time">8:40 AM, Today</span>
                                    </div>
                        </div>
                    </>);
                }
                else
                {
                    messagesPanels.push(<>
                        <div style={{ textAlign: "left" }} className="mb-1">
                                    <span style={{ color: "gray" }} >{m.userTo ? m.userTo.firstName + " " +m.userTo.lastName : ''}</span>
                                </div>
                                <div className="d-flex justify-content-start mb-4">
                                    <div className="msg_cotainer_left">
                                    {m.text}
									<span hidden className="msg_time">8:40 AM, Today</span>
                                    </div>
                                </div>
                    </>);
                }
            }
        }
    return(
        <div>
            <div className="modal fade" id={this.state.id} data-bookingidenc={this.state.bookingidEnc} tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-full" role="document">
                    <div className="modal-content">
                        <div className="modal-header" style={{height:7+"vh"}}>
                            <h5 className="modal-title">LiveChat</h5>
                            <button type="button" className="close close-bnt-fix" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body" >
                            <div className="card-body msg_card_body">
                                
                                {messagesPanels}

                                
                               
                            </div>
                        </div>
                        <div className="modal-footer">
                        <input className="form-control" name="message" onChange={this.handleChange} type="text" />

                            <div className="row">
                                <div className="col"><button onClick={this.sendMessage} disabled={disableSend} type="button" className="btn btn-primary" >invia</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    }
}

export default ChatModal; 