import React from 'react';
import { userService } from '../services/user.service';

class ButtonLogout extends React.Component {
    constructor(props) {
        super(props);        
        this.state = {};
        this.handleLogOut = this.handleLogOut.bind(this);
      }
      handleLogOut(e) {
        userService.logout();
    }
    render() {
        return (
            <div>
                <li className="nav-item">
                    <a className="nav-link" onClick={this.handleLogOut} >Esci</a>
                </li>
            </div>
        )
    }
}

export default ButtonLogout;