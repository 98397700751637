import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import PageLoader from './PageLoader';
import loadjs from 'loadjs';
import { userService } from '../services/user.service';
import Modal from './modal';
import environment from '../environment';

var Url ='';//environment.configs.APIurlProd; //
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    Url = environment.configs.APIurlLocal;
} else {
    Url =environment.configs.APIurlProd;
}

class CompanyDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            store: null,
            modalErrorDescr : '',
            modalClasstype : '',
            modalIcon : '',
            modalTitle : ''
        };
        this.claimOwnerShip = this.claimOwnerShip.bind(this);
        this.goToBooking = this.goToBooking.bind(this);
    }

    componentDidMount() {     
        loadjs(['./assets/js/custom.js'], () => {});
        console.log(this.props.match)   
        var idEnc = this.props.match.params.companyStoreIdEnc;        
        userService.getCompanyStore(idEnc).then(
            data => {
                if(data && data.data)
                {
                    console.log(data.data)
                    this.setState({ store: data.data });
                    loadjs(['./assets/js/custom.js'], () => {});
                }
                
            },
            error => {this.setState({ company: null });}
        );        
    }

    goToBooking(e)
    {
        var idEnc = this.props.match.params.companyStoreIdEnc;
        //const { from } = { from: { pathname: "/Booking/New/"+id } };
        //this.props.history.push("/Booking/New/"+idEnc);
        window.location.href = "/Booking/New/"+idEnc;
    }
    claimOwnerShip(e)
    {
        console.log(this.state.store)
        if(!this.state.store)
        return;
        if(this.state.store.idEnc)
        {
            var user = JSON.parse(localStorage.getItem('user'));
            if(user && user.userId > 0)
            {
                userService.claimOwnershipCompanyStore(this.state.store.idEnc).then(
                    data => {this.setState({ modalErrorDescr: "La richiesta è stata effettuata con successo.",modalClasstype: 'modal-success',modalIcon: 'fa fa-check',modalTitle: 'Richiesta effettuata!'  }, () => {window.$("#claimStatusModal").modal("show");});},
                    error => {this.setState({ modalErrorDescr: "Siamo spiacenti, è avvenuto un errore.",modalClasstype: 'modal-error',modalIcon: 'fa fa-close',modalTitle: 'Errore!'  }, () => {window.$("#claimStatusModal").modal("show");});}
                ); 
            }
            else
            {
                this.setState({ modalErrorDescr: "Devi effettuare il login per proseguire.",modalClasstype: 'modal-error',modalIcon: 'fa fa-close',modalTitle: 'Errore!' }, () => {window.$("#claimStatusModal").modal("show");});
                
            }
        }
        else
        {
            this.setState({ modalErrorDescr: "Siamo spiacenti, è avvenuto un errore.",modalClasstype: 'modal-error',modalIcon: 'fa fa-close',modalTitle: 'Errore!' }, () => {window.$("#claimStatusModal").modal("show");});
        }        
    }

    render() {
        let {store, companyImg, modalErrorDescr, modalClasstype, modalIcon,modalTitle} = this.state;
        var user = JSON.parse(localStorage.getItem('user'));
        var isCompany = false;
        if(user && user.userRoles.length > 0 && (user.userRoles.includes(2) || user.userRoles.includes(1)))
            isCompany = true;
        const that = this;
        if(!store)
            return (<PageLoader />);
            let storesPanels = [];
            let timeTable = [];
            var time = store.companyWeekdays ?? null;
            for(var i = 0; i < 7; i++ ){
                var t;
                if(time[i] !== "" && time[i] !== null && time.length === 7 && time[i].from_1.length >= 5 && time[i].to_1.length >= 5 && time[i].from_2.length >= 5 && time[i].to_2.length >= 5){
                    if(time[i].from_1 === time[i].to_1 && time[i].from_2 === time[i].to_2)
                        t = [<>
                            <td>chiuso</td>
                            <td></td>
                        </>]
                    else
                        t = [<>
                            <td>{time[i].from_1 !== time[i].to_1 ? time[i].from_1.substring(0,5) + '-' + time[i].to_1.substring(0,5) : 'chiuso'}</td>
                            <td>{time[i].from_2 !== time[i].to_2 ? time[i].from_1.substring(0,5) + '-' + time[i].to_2.substring(0,5) : 'chiuso'}</td>
                        </>]
                }else{
                    t = [<>
                        <td>-</td>
                        <td>-</td>
                    </>]
                }
                timeTable.push(t)
            }

            /*if(company.companyStores)
            {
                for(var i=0;i< company.companyStores.length; i ++ )
                {
                    var item = company.companyStores[i];
                    var key = "s    toresPanels" + i;
                    var c = [  <>
                    <hr />
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-10 col-md-offset-5">
                    <h5 className="card-title">Sede Numero {i}</h5>
                                        <div className="card-text">
                                            <label>Indirizzo: <b>{item.address}</b></label>
                                        </div>
                                        <div className="card-text">
                                            <label>Email: <b>{item.email}</b></label>
                                        </div>
                                        <div className="card-text">
                                            <label>Numero telefonico: <b>{item.phoneNumber}</b></label>
                                        </div>
                                    </div>
                                    <div className="col d-flex align-items-center justify-content-start ">
                                        <div className="float-right">
                                            <img src="/assets/img/person-02.jpg" height="150px" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex pt-3">
                                    <div className="col">
                                        {item.canBeBooked ? <button className="btn btn-primary float-left" >Prenota</button> : <button className="btn btn-primary float-left" disabled >Non Prenotabile</button>}                                        
                                    </div>
                                </div>
                            </div>
                            <hr />
                    </>];
                    storesPanels.push(c);
                }
            }*/
        return (   
            <div>
                
                <Modal id="claimStatusModal" classtype={modalClasstype} icon={modalIcon} title={modalTitle} description={modalErrorDescr} />
                <div id="claimModal" className="modal fade" role="dialog">
                    <div className="modal-dialog">    
                        <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Sei il proprietario di quest'attività?</h4>
                        </div>
                        <div className="modal-body">
                            <p>Se questa attività ti appartiene, clicca "Si, prosegui" per poter reclamare questa attività.</p>
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.claimOwnerShip}>Si, prosegui</button>
                            <button type="button" className="btn btn-default" data-dismiss="modal">No</button>
                        </div>
                        </div>
                    </div>
                </div>

                <section className="ts-separate-bg-element" data-bg-image="/assets/img/bg-desk.jpg" data-bg-image-opacity=".1" style={{ paddingTop: 6 + "rem", minHeight:100+"vh"}} data-bg-color="#1b1464">
                <Navbar />
                    <div className="container" >
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-10 dtlres">
                                    <div className="card-text mb-4">
                                            <button onClick={function(e){that.props.history.goBack()}} className="btn btn-link"><i className="fa fa-chevron-left" aria-hidden="true"  ></i></button><br/>
                                        </div>
                                        <div className="card-text">
                                            <label>Nome: <b>{store.company.name ?? '-'}</b></label>
                                        </div>
                                        <div className="card-text">
                                            <label>Indirizzo: <b>{store.address ?? '-'}</b></label>
                                        </div>
                                        <div className="card-text">
                                            <label>Categoria: <b>{store.company.companyCategories && store.company.companyCategories.length > 0 ? store.company.companyCategories[0].description: '-'}</b></label>
                                        </div>
                                        <div className="card-text">
                                            <label>Descrizione: <b>{store.company.description ?? '-'}</b></label>
                                        </div>
                                    </div>
                                    <div className="col d-flex align-items-center justify-content-start">
                                        <div className="float-right">
                                            <img className="img-com" src={Url+'/company/get-company-image/'+store.company.idEnc} height="150px" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex pt-3">
                                        <div style={{paddingLeft: 6+"px", paddingRight: 6+"px"}}>
                                            {store.email ? <a className="btn btn-primary float-left btndetail mt-2 ml-2" href={'mailto:' + store.email}>Invia Messaggio</a> : <></>}
                                            {store.phoneNumber ? <a className="btn btn-primary float-left btndetail mt-2 ml-2" href={'tel:' + store.phoneNumber}>Chiama</a> : <></>}
                                            {store.canBeBooked && !isCompany ? <button className="btn btn-primary btndetail float-left mt-2 ml-2" onClick={this.goToBooking} >Prenota</button> : <></>}
                                            {store.company.userId != null && store.company.userId > 0 ? <></> : <><button className="btn float-left btndetail mt-2 ml-2" data-toggle="modal" style={{backgroundColor:"#808080", color:"#fff"}} data-target="#claimModal" >Reclama Esercizio</button></>}
                                        </div>
                                    </div>
                            </div>
                            <hr />
                            <div  className="card-body">
                                <div className="row">
                                <h5 className="card-title">Orari</h5>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Giorno</th>
                                                <th>Orario</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            < tr >
                                                <td>Lun</td>
                                                {timeTable[0]}
                                            </tr >
                                            <tr>
                                                <td>Mar</td>
                                                {timeTable[1]}
                                            </tr>
                                            <tr>
                                                <td>Mer</td>
                                                {timeTable[2]}
                                            </tr>
                                            <tr>
                                                <td>Gio</td>
                                                {timeTable[3]}
                                            </tr>
                                            <tr>
                                                <td>Ven</td>
                                                {timeTable[4]}
                                            </tr>
                                            <tr>
                                                <td>Sab</td>
                                                {timeTable[5]}
                                            </tr>
                                            <tr>
                                                <td>Dom</td>
                                                {timeTable[6]}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {storesPanels}                            
                        </div>
                    </div>
                    <Footer />
                </section>
            </div>
        )
    }
}

export default CompanyDetails;