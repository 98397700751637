import React from 'react';
import ReactDOM from 'react-dom';
import { userService } from '../services/user.service';
import Modal from './modal';

class SearchBar extends React.Component {
    constructor(props) {
        super(props);

        this.google = window.google;
        this.autocompleteInput = React.createRef();
        this.autocompleteCity = null;

        this.state = {
            ListCategory: [],
            ListTopCategory: [],
            Category: 0,
            Lon: '', Lat: '', City: ''
        };

        //Fields onChange
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this);
        //Submit
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        //Autocoplete cities from API Google
        this.autocompleteCity = new this.google.maps.places.Autocomplete(this.autocompleteInput.current,
            { types: ['geocode'], componentRestrictions: {country: "it"} });
            this.autocompleteCity.addListener('place_changed', this.handleChangeCity);
            const that = this;
            /*userService.getTopCategories().then(
                data => {
                    that.setState({ ListTopCategory: data });
                },
                error => {that.setState({ ListTopCategory: [] });}
            ); */       
            userService.getCategories().then(
                data => {
                    that.setState({ ListCategory: data });
                },
                error => {that.setState({ ListCategory: [] });}
            );         
           
    }

    //Fields
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    //GET latitude and longitude from GOOGLE MAPS
    handleChangeCity() {       
        const place = this.autocompleteCity.getPlace();
        if(!place.geometry.location)
            return;
        const lan = place.geometry.location.lat();
        const lng = place.geometry.location.lng();        
        this.setState({ Lon: lng });
        this.setState({ Lat: lan });
        this.setState({ City: place.formatted_address })
    }

    handleSubmit(event){
        event.preventDefault(); 
        const { Lat, Lon, Category } = this.state; 
        if(Lat && Lon)
        {           
            this.props.isLoadingCallback();
            userService.getCompaniesStoresList(Lon, Lat,5000, Category).then(
                data => {
                    this.props.parentCallback(data, Lon, Lat);
                },
                error => {window.$("#searchModalError").modal("show");}
            );        
        }
        else
        {
            window.$("#searchModalwarning").modal("show");
        }
    }

    render() {     
        let topCatPanels = [];
        const { ListTopCategory} = this.state; 
        if(ListTopCategory)
        {
            for(var i=0;i< ListTopCategory.length; i ++ )
            {
                var item = ListTopCategory[i];
                var key = "topcategorykey" + i;
                var c = [  <div key={key} className="mx-3 box-category " style={{ marginTop: 2 + "vh" }} >
                <div className="col text-center">
                    <i className="fa fa-heart icon-box" />
            <p style={{ color: "white" }}>{item.text}</p>
                </div>
            </div>];
            topCatPanels.push(c);
            }
        }
        return (
                <div className="container" style={{marginBottom: 15+"vh"}}>
                    <Modal id="searchModalwarning" classtype='modal-warning' icon='fa fa-close' title='Attenzione!' description='Specificare il luogo.' /> 
                    <Modal id="searchModalError" classtype='modal-warning' icon='fa fa-close' title='Attenzione!' description='Un errore è avvenuto durante la ricerca.' /> 
                <section className="ts-separate-bg-element">
                        <div className="ts-box mb-0 p-4 ts-mt__n-10" style={{borderRadius: 10+"px"}}>
                            <form onSubmit={this.handleSubmit}>
                                <div className="row" >                                    
                                    <div className="col-sm row-center">
                                        <div className="form-group">
                                            <select className="form-control" name="Category" onChange={this.handleChange} placeholder="Seleziona la categoria" >
                                                <option value={0}>Seleziona la categoria</option>
                                                {
                                                    this.state.ListCategory !== null ? this.state.ListCategory.map(item => (
                                                        <option key={item.value} value={item.value} readOnly={true}>{item.text}</option>
                                                    )) : ''
                                                }
                                            </select>
                                        </div>
                                    </div>
                                <div className="col-sm row-center">
                                    <div className="form-group">
                                        <input className="form-control" type="text" ref={this.autocompleteInput} placeholder="Inserisci il luogo" />
                                    </div>
                                </div>
                                <div className="btnsearch">
                                    <div className="form-group clearfix row-center col-sm" >
                                        <button className="btn btn-primary float-right" id="form-submit">Cerca</button>
                                    </div>
                                </div>
                            </div>
                            </form>
                        </div>
                </section>
                    <div className="row d-flex justify-content-center" >
                       {topCatPanels}
                    </div>
                </div>
        );
    }
}

export default SearchBar;
