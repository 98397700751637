
import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import isEqual from 'lodash.isequal';
import Modal from './modal';

class GoogleMapSearch extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {
      center : null,
      setCenter:{},
      zoom: 13,
      setZoom : 0,
      points: [],
      description : ''
  };
  this.showInfo = this.showInfo.bind(this);
}
componentDidMount()
{
  this.setState({ points: this.props.points });
  this.setState({ center: this.props.center });
}
showInfo(descr)
{
  console.log("qedewdf")
  this.setState({ description: descr }, function () {
    window.$("#markerModal").modal('show');
});

}

    /*const points = [
        { id = 0,letter: "M", tooltip: "Milano", lat: 45.463619, lng: 9.188120 },
        { id = 0,letter: "V", tooltip: "The Long Water", lat: 51.508, lng: -0.175 },
        { id = 0,letter: "P", tooltip: "The Serpentine", lat: 51.505, lng: -0.164 }
      ];*/
      render() {   
        if(this.props.center == null)
        return (<></>)
      const {points, description} = this.state;
      let markersPoints = [];
      if(points && points.length > 0)
      {
        for(var i = 0; i < points.length; i ++)
        {
          var item = points[i][0];
          var key = "marker"+ item.id;
          markersPoints.push([<Marker 
            showInfo={this.showInfo}
            key={key}
            lat={item.lat}
            lng={item.lng}
            letter={item.letter}
            tooltip={item.tooltip}
            description={item.description}
          /> ]);
        }
        //console.log(markersPoints)
      }
      const {center, setCenter} = this.state;// useState({lat: 45.463619, lng: 9.188120 });
      const {zoom, setZoom} =this.state;// useState(8);
      return (
          <div style={{ height: '50vh', width: '100%' }}>
            
        <Modal id="markerModal" classtype='modal-success' icon='fa fa-check' title='Info' description={description} />
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyA29Br8PmhxU367FHOL0mSRtTgPQ_gmUw4' }}
            center={center}
            zoom={zoom}
          >
        {markersPoints}
          </GoogleMapReact>
        </div>
      );
  }
}
  export default GoogleMapSearch;