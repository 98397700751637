import { authHeader } from '../helpers/auth-header';
import environment from '../environment';
import process from "process";
//URL PRODUTION AND LOCAL
//const UrlProd = environment.configs.APIurlProd;
var Url ='';//environment.configs.APIurlProd; //
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    Url = environment.configs.APIurlLocal;
} else {
    Url =environment.configs.APIurlProd;
}
export const userService = {
    login,
    logout,    
    ConfirmEmail,
    ChangePassword,
    ChangePasswordRequest,
    getCategories,
    getCompaniesList,
    getTopCategories,    
    getCompanyStore,
    getAppointmentsList,
    BookAppointment,
    DeleteAppointment,
    UpdateAppointment,
    UploadImage,
    getCompaniesStoresList,
    GetRangeOfDaysData,
    claimOwnershipCompanyStore,
    getAppointment,
    ListMyStores,
    GetMessagesList,
    SendMessage
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };    
    return fetch(`${Url}/user/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a user in the response
            if (user) {
                //console.log(user)
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
                //user.authdata = window.btoa(user.Id);
                //console.log(user)
                localStorage.setItem('user', JSON.stringify(user.data));
            }

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    window.location.reload(true);
}



function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);        
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function ConfirmEmail(token)
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader('application/json')
    };    
    return fetch(`${Url}/user/confirm-email/`+token, requestOptions)
    .then(handleResponse)
    .then(data => {        
        return data
    });    
}

function ChangePassword(email, newPassword, token)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, newPassword, token })
    };    
    return fetch(`${Url}/user/change-password`, requestOptions)
    .then(handleResponse)
    .then(data => {        
        return data
    });    
}

function ChangePasswordRequest(email)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(email)
    };    
    return fetch(`${Url}/user/change-password-request`, requestOptions)
    .then(handleResponse)
    .then(data => {        
        return data
    });    
}

function getCategories()
{
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };    
    return fetch(`${Url}/company/get-categories`, requestOptions)
    .then(handleResponse)
    .then(data => {
        if(data)
        {
            return data.data
        }
        return null;
    });    
}

function getCompaniesList(lon, lat, maxDistanceMeters, companyCategoryId)
{
    const requestOptions = {
        method: 'POST',
        headers: authHeader('application/json'),
        body: JSON.stringify({ lon, lat,maxDistanceMeters, companyCategoryId })
    };    
    return fetch(`${Url}/company/companies-list`, requestOptions)
    .then(handleResponse)
    .then(data => {        
        return data
    });    
}

function UploadImage(image, type, companyId, companyStoreId)
{
    const requestOptions = {
        method: 'POST',
        headers: authHeader('application/json'),
        body: JSON.stringify({ image, type, companyId, companyStoreId })
    };    
    return fetch(`${Url}/company/upload-image`, requestOptions)
    .then(handleResponse)
    .then(data => {        
        return data
    });    
}

function getCompaniesStoresList(lon, lat, maxDistanceMeters, companyCategoryId)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },//authHeader('application/json'),
        body: JSON.stringify({ lon, lat, maxDistanceMeters, companyCategoryId })
    };    
    return fetch(`${Url}/company/stores-list`, requestOptions)
    .then(handleResponse)
    .then(data => {        
        return data
    });    
}

function GetRangeOfDaysData(from, to, companyStoreIdEnc)
{
    const requestOptions = {
        method: 'POST',
        headers: authHeader('application/json'),
        body: JSON.stringify({ from, to, companyStoreIdEnc })
    };    
    return fetch(`${Url}/company/get-range-days-data`, requestOptions)
    .then(handleResponse)
    .then(data => {        
        return data
    });    
}

function getTopCategories()
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader('application/json')
    };    
    return fetch(`${Url}/company/get-top-categories`, requestOptions)
    .then(handleResponse)
    .then(data => {
        if(data)
        {
            return data.data
        }
        return null;
    });    
}

function claimOwnershipCompanyStore(CompanyidEnc)
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader('application/json')
    };    
    return fetch(`${Url}/company/claim-ownership/`+CompanyidEnc, requestOptions)
    .then(handleResponse)
    .then(data => {
        if(data)
        {
            return data
        }
        return null;
    });
}

function getCompanyStore(idEnc)
{
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }//authHeader('application/json')
    };    
    return fetch(`${Url}/company/get-store/`+idEnc, requestOptions)
    .then(handleResponse)
    .then(data => {
        if(data)
        {
            return data
        }
        return null;
    });
}

function getAppointmentsList(companyStoreIdEnc, userId, dateFrom, dateTo)
{
    const requestOptions = {
        method: 'POST',
        headers: authHeader('application/json'),
        body: JSON.stringify({ companyStoreIdEnc, userId, dateFrom, dateTo })
    };    
    return fetch(`${Url}/booking/list`, requestOptions)
    .then(handleResponse)
    .then(data => {        
        return data
    });    
}

function BookAppointment(slot, date, notes, CompanyStoreIdEnc)
{
    const requestOptions = {
        method: 'POST',
        headers: authHeader('application/json'),
        body: JSON.stringify({ slot, date, notes, CompanyStoreIdEnc})
    };    
    return fetch(`${Url}/booking/book-appointment`, requestOptions)
    .then(handleResponse)
    .then(data => {        
        return data
    });    
}

function DeleteAppointment(bookingIdEnc)
{
    const requestOptions = {
        method: 'POST',
        headers: authHeader('application/json'),
        body: JSON.stringify({ bookingIdEnc})
    };
    console.log(JSON.stringify({ bookingIdEnc}))    
    return fetch(`${Url}/booking/delete-appointment`, requestOptions)
    .then(handleResponse)
    .then(data => {        
        return data
    });    
}

function UpdateAppointment(bookingIdEnc, state, date, notes, companyStoreIdEnc)
{
    const requestOptions = {
        method: 'POST',
        headers: authHeader('application/json'),
        body: JSON.stringify({ bookingIdEnc, state, date, notes, companyStoreIdEnc })
    };    
    return fetch(`${Url}/booking/update-appointment`, requestOptions)
    .then(handleResponse)
    .then(data => {        
        return data
    });    
}

function getAppointment(idEnc)
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader('application/json')
    };    
    return fetch(`${Url}/booking/get/`+idEnc, requestOptions)
    .then(handleResponse)
    .then(data => {
        if(data)
        {
            return data
        }
        return null;
    });
}

function ListMyStores()
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader('application/json')
    };    
    return fetch(`${Url}/company/my-stores-list`, requestOptions)
    .then(handleResponse)
    .then(data => {
        if(data)
        {
            return data
        }
        return null;
    });
}

function GetMessagesList(bookingIdEnc)
{
    const requestOptions = {
        method: 'POST',
        headers: authHeader('application/json'),
        body: JSON.stringify({ bookingIdEnc })
    };    
    return fetch(`${Url}/notification/messages-list`, requestOptions)
    .then(handleResponse)
    .then(data => {        
        return data
    });    
}

function SendMessage(bookingIdEnc, notes)
{
    const requestOptions = {
        method: 'POST',
        headers: authHeader('application/json'),
        body: JSON.stringify({ bookingIdEnc, notes })
    };    
    return fetch(`${Url}/notification/send-message`, requestOptions)
    .then(handleResponse)
    .then(data => {        
        return data
    });    
}