import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import loadjs from 'loadjs';
import PageLoader from './PageLoader';
import { userService } from '../services/user.service';
import DatePicker, { registerLocale } from 'react-datepicker';
import ChatModal from './ChatModal';
import it from "date-fns/locale/it";
import moment from "moment";
import 'react-datepicker/dist/react-datepicker.css';

registerLocale("it", it);

class TableCompany extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            StartDate: moment().toDate(),
            EndDate: moment().add(30, 'days').toDate(),
            CompanyStoreIdEnc: "",
            appointments: [],
            isLoaded: true,
            appointmentDate: null,
            selectedCompanyStoreId: null,
            selectedbookingIdEnc: null,
            notes: "",
            options : []
        };      
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleDecline = this.handleDecline.bind(this);
        this.handleApprove = this.handleApprove.bind(this);
        this.openUpdateModal = this.openUpdateModal.bind(this);
        this.openMessagesModal = this.openMessagesModal.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }
    componentDidMount() {
        loadjs(['./assets/js/custom.js'], () => {});
        /*let user = JSON.parse(localStorage.getItem('user'));  
        userService.getAppointmentsList(this.state.CompanyStoreId,user.userId).then(
            data => {
                if(data && data.ok)
                {
                    this.setState({appointments: data.data ?? [], isLoaded: true})                   
                    loadjs(['./assets/js/custom.js'], () => {});
                }                
            },
        error => {this.setState({ isLoaded: true });loadjs(['./assets/js/custom.js'], () => {});}
        ); */
        userService.ListMyStores().then(
            data => {
                if(data && data.ok)
                {
                    let opts = [];
                   var stores = data.data;
                   for(var i=0; i < stores.length; i ++)
                   {
                        var s = stores[i];
                        var key="opt"+i;
                        if(this.state.CompanyStoreIdEnc === "")
                        {
                            console.log(s)
                            this.setState({ CompanyStoreIdEnc: s.companyStoreIdEnc });
                        }
                        opts.push(<option key={key} value={s.companyStoreIdEnc}>{s.address}</option>)
                   }
                   this.setState({options: opts})
                }                
            },
        error => {}
        ); 
    }

    handleStartDateChange(date)
    {
        this.setState({
            StartDate: date
          })
    }
    handleEndDateChange(date)
    {
        this.setState({
            EndDate: date
          })
    }
    handleChange(event)
    {
        const { name, value } = event.target;
        this.setState({[name]: value});
    }
    handleDecline(e)
    {
        const { selectedbookingIdEnc, appointmentDate, notes } = this.state;
        userService.UpdateAppointment(selectedbookingIdEnc, 2, appointmentDate, notes, '').then(
            data => {
                if(data && data.ok)
                {
                   console.log(data)
                   const {appointments} = this.state;
                   if(appointments)
                   {
                       for(var i=0;i < appointments.length; i++)
                       {
                           var a = appointments[i];
                           if(a.idEnc == selectedbookingIdEnc)
                           {
                            appointments[i].bookingState = 2;
                            appointments[i].when = appointmentDate;
                            break;
                           }
                       }
                       this.setState({
                        appointments: appointments
                      })

                   }
                }                
            },
        error => {}
        );
    }

    handleSearch(event)
    {
        loadjs(['./assets/js/custom.js'], () => {});
        this.setState({ isLoaded: false });
        //let user = JSON.parse(localStorage.getItem('user')); 
        const {StartDate,EndDate,CompanyStoreIdEnc} = this.state; 
        console.log(CompanyStoreIdEnc)

        userService.getAppointmentsList(CompanyStoreIdEnc,0, StartDate, EndDate).then(
            data => {
                if(data && data.ok)
                {
                    this.setState({appointments: data.data ?? [], isLoaded: true})                   
                    loadjs(['./assets/js/custom.js'], () => {});
                }                
            },
            error => {this.setState({ appointments: [], isLoaded: true });loadjs(['./assets/js/custom.js'], () => {});}
        );
    }

    handleApprove(e)
    {
        const { selectedbookingIdEnc, appointmentDate, notes } = this.state;
        userService.UpdateAppointment(selectedbookingIdEnc, 1, appointmentDate, notes, '').then(
            data => {
                if(data && data.ok)
                {
                   console.log(data)
                   const {appointments} = this.state;
                   if(appointments)
                   {
                       for(var i=0;i < appointments.length; i++)
                       {
                           var a = appointments[i];
                           if(a.idEnc == selectedbookingIdEnc)
                           {
                            appointments[i].bookingState = 1;
                            appointments[i].when = appointmentDate;
                            break;
                           }
                       }
                       this.setState({
                        appointments: appointments
                      })

                   }
                }                
            },
        error => {}
        );
    }

    handleChangeDate(date) {
        this.setState({
          appointmentDate: date
        })
      }

      openUpdateModal(event)
      {
        var idEnc = event.currentTarget.dataset.idenc;
        const {appointments} = this.state;
        var selectedAppointment = appointments.find(x => x.idEnc == idEnc);
        console.log(selectedAppointment)
        this.setState({selectedbookingIdEnc: idEnc ?? null, appointmentDate: moment(selectedAppointment.when).toDate()}, () => {window.$("#updateAppointmentModal").modal("show");});        
      }
      openMessagesModal(event)
      {
        var idEnc = event.currentTarget.dataset.idenc;       
        this.setState({selectedbookingIdEnc: idEnc ?? null}, () => {window.$("#messagesAppointmentModal").modal("show");});        
     
      }
   
    render() {
        let tableRows = [];
        const {selectedbookingIdEnc, options, appointments, isLoaded, appointmentDate, notes, StartDate, EndDate} = this.state;
        if(!isLoaded)
            return (<PageLoader />);
            if(appointments && appointments.length > 0)
            {
                for(var i=0; i< appointments.length;i++)
                {
                    var a = appointments[i];

                    //console.log(a)
                    var dt = new Date(a.when);
                    var key = "appointmentTableCompany"+i;
                    var state = '';
                    switch(a.bookingState) {
                        case 1:
                            state = "Approvata";
                          break;
                        case 2:
                            state = "Declinata";
                          break;
                        case 3:
                            state = "Cancellata";
                          break;
                        default:
                            state = "In Attesa";
                      }
                    var time = `${
                        (dt.getDate()).toString().padStart(2, '0')}/${
                        (dt.getMonth()+1).toString().padStart(2, '0')}/${
                        dt.getFullYear().toString().padStart(4, '0')} ${
                        dt.getHours().toString().padStart(2, '0')}:${
                        dt.getMinutes().toString().padStart(2, '0')}`;
                    let row = [<tr key={key}>
                        <th className="tb-row" scope="row">{i + 1}</th>
                        <td className="tb-row">{a.user.firstName ?? ''}</td>
                        <td className="tb-row">{a.user.lastName ?? ''}</td>
                        <td className="tb-row">{a.CompanyName ?? ''}</td>
                        <td className="tb-row">{a.companyStore.address ?? ''}</td>
                        <td className="tb-row">{time ?? ''}</td>
                        <td className="tb-row"><button type="button" onClick={this.openMessagesModal} data-idenc={a.idEnc} className="btn btn-info">Visualizza note</button></td>
                        <td className="tb-row"><b>{state}</b></td>
                        <td className="tb-row">{a.bookingState != 3 ? <button type="button" onClick={this.openUpdateModal} data-idenc={a.idEnc} className="btn btn-warning">Modifica</button> : <></>}</td>
                    </tr>]
                    tableRows.push(row)
                }                
            }
        return (
            <div>
                  <ChatModal bookingidenc={selectedbookingIdEnc} id="messagesAppointmentModal"/>
                <div className="modal" id="updateAppointmentModal">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Modifica Appuntamento</h4>
        <button type="button" className="close" data-dismiss="modal">&times;</button>
      </div>
      <div className="modal-body">
        <div className="form-group">
            <label>Orario</label>
            <DatePicker  className="form-control"          
      selected={appointmentDate}
      onChange={this.handleChangeDate}
      locale="it"
      showTimeSelect
      timeFormat="p"
      timeIntervals={1}
      dateFormat="dd/MM/yyyy hh:mm"
    />
        </div>
        <div className="form-group">
            <label>Note</label>
            <textarea className="form-control" name="notes" onChange={this.handleChange} value={notes}/>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-success" onClick={this.handleApprove} data-dismiss="modal">Approva</button>
        <button type="button" className="btn btn-danger" onClick={this.handleDecline} data-dismiss="modal">Declina</button>
        <button type="button" className="btn btn-primary" data-dismiss="modal">Chiudi</button>
      </div>

    </div>
  </div>
</div>
                <section className="ts-separate-bg-element" data-bg-image="/assets/img/bg-desk.jpg" data-bg-image-opacity=".1" style={{ paddingTop: 6 + "rem", minHeight: 100 + "vh" }} data-bg-color="#1b1464">
                    <Navbar />
                    <div className="container pt-4">
                        <div style={{marginBottom: .8+"rem"}}>
                            <div style={{paddingLeft: 1.25+"rem",paddingRight: 1.25+"rem"}}>
                                <div className="row" >
                                    <div className="col-sm" >
                                        <div className="form-group">
                                            <label style={{ color: "#fff" }} >Sede</label>
                                            <select className="form-control" name="CompanyStoreIdEnc" onChange={this.handleChange} placeholder="Sede" >
                                                {options}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm">
                                        <div className="form-group">
                                            <label style={{ color: "#fff" }} >Data Inizio</label>
                                            <DatePicker className="form-control" dateFormat="dd/MM/yyyy" locale="it" selected={StartDate} onChange={this.handleStartDateChange} name="StartDate" />
                                        </div>
                                    </div>
                                    <div className="col-sm">
                                        <div className="form-group">
                                            <label style={{ color: "#fff" }} >Data Fine</label>
                                            <DatePicker className="form-control" dateFormat="dd/MM/yyyy" locale="it" name="EndDate" onChange={this.handleEndDateChange} selected={EndDate} />
                                        </div>
                                    </div>
                                    <div className="col-sm">
                                        <div className="form-group">                                       
                                            <button style={{ marginTop: "25px" }} type="button" className="btn btn-primary form-control" onClick={this.handleSearch}>Cerca</button>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive-lg">
                            <table className="table table-hover" style={{ verticalAlign: "middle", backgroundColor: "white" }}>
                                <thead >
                                    <tr >
                                        <th scope="col">#</th>
                                        <th scope="col">Nome</th>
                                        <th scope="col">Cognome</th>
                                        <th scope="col">Indirizzo</th>
                                        <th scope="col">Data</th>
                                        <th scope="col" >Note</th>
                                        <th scope="col">Stato</th>
                                        <th scope="col" style={{ width: 110 + "px" }} ></th>
                                        <th scope="col" style={{ width: 110 + "px" }} ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {tableRows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}

export default TableCompany;