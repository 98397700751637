import React from 'react';
import loadjs from 'loadjs';
import { userService } from '../services/user.service';
import Modal from './modal';

class ConfirmRegistration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmed: false
        };
      }
    componentDidMount()
    {
        loadjs(['./assets/js/custom.js'], () => {});
        var token = this.props.match.params.token
        userService.ConfirmEmail(token).then(
            data => {
                if(data.ok === true) 
                    this.setState({ confirmed: true });
            },
            error => {
            }
        );
    }

    render() {
        let {confirmed} = this.state;
        return (
            <div className="ts-page-wrapper" id="page-top">
                <header id="ts-hero" className="d-block py-5 ts-separate-bg-element" data-bg-blend-mode="multiply"
                    data-bg-parallax="scroll" data-bg-parallax-speed="3" data-bg-particles-line-color="rgba(255,255,255,.6)"
                    data-bg-particles-dot-color="rgba(255,255,255,.1)" data-bg-particles-parallax="1">
                    <div className="container align-self-center py-5">
                        <div className="row mb-5">
                            <div className="col-md-10" style={{textAlign: "center"}}>
                                <h1>REGISTRAZIONE AVVENUTA CON SUCCESSO!</h1>
                            </div>
                            <div className="col-md-2 d-flex justify-content-center">
                                <img src="/assets/img/confirmation.png" width="150px" height="150px" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="ts-background" data-bg-image-opacity=".6">
                        <div className="ts-svg ts-z-index__2">
                            <img src="/assets/svg/wave-static-02.svg"
                                className="w-100 position-absolute ts-bottom__0 ts-height__200px" alt="" />
                            <img src="/assets/svg/wave-static-01.svg"
                                className="w-100 position-absolute ts-bottom__0 ts-height__100px" alt="" />
                        </div>
                        <div className="ts-background-image ts-parallax-element" data-bg-color="#1A1462"
                            data-bg-blend-mode="multiply"></div>
                    </div>
                </header>
                <main id="ts-content">
                    <section id="demos" className="ts-block text-center" style={{paddingTop: 5+"rem"}}>
                        <div className="container">
                            {confirmed ?<div className="ts-title">
                                <p style={{ fontSize: "x-large" }}>Grazie per esserti registrato.<br /></p>
                                <div className="form-group clearfix">
                                    <div className="form-group clearfix">
                                         <a href="/login" ><button className="btn btn-primary" id="btn-lgn" >Accedi</button></a>
                                    </div>
                                </div>
                            </div> : <div className="ts-title"><p style={{ fontSize: "x-large" }}>avvenuto un errore, riprovare.<br /></p></div>}
                        </div>
                    </section>
                </main>
                <footer id="ts-footer">
                    <div className="p-4 ts-block text-center">
                        <div className="container">
                            <small>© 2020 Reindal, Tutti i diritti riservati</small>
                        </div>
                    </div>
                </footer>
            </div>
        )

    }
}

export default ConfirmRegistration;