import React from 'react';
import { userService } from '../services/user.service';
import loadjs from 'loadjs';
import Navbar from './Navbar';
import PageLoader from './PageLoader';
import Footer from './Footer';

class TableAppointment extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            appointments: [],
            isLoaded: false
        };      
        this.handleRowClick = this.handleRowClick.bind(this);

    }
    componentDidMount() {
        loadjs(['./assets/js/custom.js'], () => {});
        let user = JSON.parse(localStorage.getItem('user'));  
        userService.getAppointmentsList(0,user.userId).then(
            data => {
                if(data && data.ok)
                {
                    this.setState({appointments: data.data ?? [], isLoaded: true})                   
                    loadjs(['./assets/js/custom.js'], () => {});
                }
                
            },
            error => {this.setState({ appointments: [], isLoaded: true });loadjs(['./assets/js/custom.js'], () => {});}
        );  
    }

    handleRowClick(event){       
        var idEnc =   event.currentTarget.dataset.idenc;
        const { from } = { from: { pathname: "/Booking/Detail/"+idEnc } };
        this.props.history.push(from);
    }

    render() {
        let tableRows = [];
        const {appointments, isLoaded} = this.state;
        if(!isLoaded)
            return (<PageLoader />);
        if(appointments && appointments.length > 0)
        {
            for(var i=0; i< appointments.length;i++)
            {
                var a = appointments[i];
                console.log(a)
                var dt = new Date(a.when);
                var key = "appointmentTable"+i;
                var state = '';
                switch(a.bookingState) {
                    case 1:
                        state = "Approvata";
                      break;
                    case 2:
                        state = "Declinata";
                      break;
                    case 3:
                        state = "Cancellata";
                      break;
                    default:
                        state = "In Attesa";
                  }
                  var time = `${
                    (dt.getDate()).toString().padStart(2, '0')}/${
                    (dt.getMonth()+1).toString().padStart(2, '0')}/${
                    dt.getFullYear().toString().padStart(4, '0')} ${
                    dt.getHours().toString().padStart(2, '0')}:${
                    dt.getMinutes().toString().padStart(2, '0')}`;
                let row = [<tr className="bghover" key={key} style={{ cursor: "pointer" }} data-idenc={a.idEnc} onClick={this.handleRowClick}>
                <th className="tb-row" scope="row">{i + 1}</th>
                <td className="tb-row">{a.companyName}</td>
                <td className="tb-row">{a.companyStore.address}</td>
                <td className="tb-row">{time}</td>
                <td className="tb-row"><b>{state}</b></td>
            </tr>]
            console.log(a.bookingState);
            tableRows.push(row)
            }
            
        }
        return (
            <div>
                <section className="ts-separate-bg-element" data-bg-image="/assets/img/bg-desk.jpg" data-bg-image-opacity=".1" style={{ paddingTop: 6 + "rem", minHeight: 100 + "vh" }} data-bg-color="#1b1464">
                    <Navbar />
                    <div className="container pt-4">
                        <h3 style={{color: "#f26d7d", textAlign: "center"}} className="pb-2" >Riepilogo Appuntamenti</h3>
                        <div className="table-responsive-lg">
                            <table className="table table-hover" style={{ verticalAlign: "middle", backgroundColor: "white" }}>
                                <thead >
                                    <tr style={{color: "#f26d7d"}} >
                                        <th scope="col"></th>
                                        <th scope="col">Sede</th>
                                        <th scope="col">Indirizzo</th>
                                        <th scope="col">Data</th>
                                        <th scope="col">Stato Richiesta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}

export default TableAppointment;