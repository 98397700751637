import React from 'react';

class ButtonBookingList extends React.Component {

    render() {
        var user = JSON.parse(localStorage.getItem('user'));
        return (
            <div className="navbar-nav nav-flex-icons menu-link">
                <li className="nav-item">
                    <a className="nav-link" href={user && user.userRoles.length > 0 && (user.userRoles.includes(2) || user.userRoles.includes(1)) ? "/Booking/History" : "/Booking/List"}>I miei appuntamenti</a>
                </li>
            </div>
        )
    }
}

export default ButtonBookingList;