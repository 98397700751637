import React from 'react';

class HelpAppointment extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            description: '',
        };      
    }
    componentDidMount() {       
        this.setState({
            description: this.props.description
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.description !== prevProps.description) {
            this.componentDidMount();
        }
    }
    render() {
        return (
            <div>
                <div className="container text-light">
                    <div className="row d-flex justify-content-center mt-5">
                        <i className="fa fa-calendar-check-o" style={{ fontSize: 5 + "vw" }}></i>
                    </div>
                    <div className="row d-flex justify-content-center mt-4">
                        <h3 className="ts-opacity__50 white mb-2 text-center" >Seleziona la data e l'orario</h3>
                    </div>
                    <div className="row d-flex justify-content-center text-center mt-1 ">
                        <p style={{color:"white"}}>{this.state.description}</p>
                    </div>
                    <div className="row mt-1">
                        <div>
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HelpAppointment;