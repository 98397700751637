import React from 'react';

class Footer extends React.Component {

    render() {
        return (
            <div>
                <footer id="ts-footer"  >
                    <section className="ts-separate-bg-element" data-bg-image-opacity=".1" data-bg-color="#1b1464">
                        <div className="container">
                            <div className="text-center text-white py-4">
                                <small>© 2020 Reindal, Tutti i diritti riservati</small>
                            </div>
                        </div>
                    </section>
                </footer>
            </div>
        )
    }
}

export default Footer;