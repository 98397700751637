import React from 'react';
import ButtonLogin from './ButtonLogin';
import ButtonLogout from './ButtonLogout';
import ButtonBookingList from './ButtonBookingList';
import IconUser from './IconUser';

class Navbar extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
        };      
    }
    render() {  
        const afterLogin  = <> <ButtonBookingList/> <ButtonLogout /> </>      
        return (
            <header>
                <nav className="navbar menu-bar fixed-top navbar-expand-lg navbar-dark scrolling-navbar">
                    <a className="navbar-brand" href="/">
                        <img src="/assets/img/2000w-white.png" width="200px" height="45px" alt="" />
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                        </ul>
                        <ul className="navbar-nav nav-flex-icons menu-link">
                            {localStorage.getItem('user') ?  afterLogin :< ButtonLogin/>}    
                        </ul>
                    </div>
                </nav>
            </header>

        )
    }
}

export default Navbar;