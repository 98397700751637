import React from 'react';
import InputDay from './InputDay';
import InputNote from './InputNote';
import InputCalendar from './InputCalendar';
import HelpAppointment from './HelpAppointment';
import Navbar from './Navbar';
import Footer from './Footer';
import loadjs from 'loadjs';
import Modal from './modal';
import { userService } from '../services/user.service';

class Appointment extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            rangeOfDays: null,
            selectedDay: null,
            selectedTime: "1",
            selectedNote: ""
        }; 
        
        this.callbackMonthChange = this.callbackMonthChange.bind(this);
        this.enableFirstStep = this.enableFirstStep.bind(this);
        this.enableSecondStep = this.enableSecondStep.bind(this);   
        this.enableThirdStep = this.enableThirdStep.bind(this);  
        this.bookAppointment = this.bookAppointment.bind(this);
    }
    
    componentDidMount()
    {
        loadjs(['./assets/js/custom.js'], () => {});   
        //onMonthChange 
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);              
        var idEnc = this.props.match.params.companyStoreIdEnc;        
        userService.GetRangeOfDaysData(firstDay.toJSON(), lastDay.toJSON(),idEnc).then(
            data => {
                if(data && data.data)
                {
                    this.setState({rangeOfDays: data.data});
                    
                    loadjs(['./assets/js/custom.js'], () => {});
                }
                
            },
            error => {this.setState({ company: null });}
        ); 
        
    }
    onClickBack(){
        if (document.getElementById("inputNote").style.display === "block") {

            document.getElementById("arrowNext").style.display = "block";
            document.getElementById("inputNote").style.display = "none";
            document.getElementById("infoNote").style.display = "none";
            document.getElementById("inputDay").style.display = "block";
            document.getElementById("infoDay").style.display = "block";  

        } else if (document.getElementById("inputDay").style.display === "block") {

            document.getElementById("arrowBack").style.display = "none";
            document.getElementById("inputDay").style.display = "none";
            document.getElementById("infoDay").style.display = "none";
            document.getElementById("inputCalendar").style.display = "block";
            document.getElementById("infoCalendar").style.display = "block";
        }
    } 

    onClickNext(){
        if (document.getElementById("inputCalendar").style.display === "block") {
            
            document.getElementById("arrowBack").style.display = "block";
            document.getElementById("inputCalendar").style.display = "none";
            document.getElementById("infoCalendar").style.display = "none";
            document.getElementById("inputDay").style.display = "block";
            document.getElementById("infoDay").style.display = "block";

        } else if (document.getElementById("inputDay").style.display === "block") {

            document.getElementById("arrowNext").style.display = "none";
            document.getElementById("inputDay").style.display = "none";
            document.getElementById("infoDay").style.display = "none";
            document.getElementById("infoNote").style.display = "block";
            document.getElementById("inputNote").style.display = "block";
        } 
    }
    callbackMonthChange(fDay)
    {
        var date = new Date(fDay);
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);        
        var id = this.props.match.params.companyStoreIdEnc;        
        userService.GetRangeOfDaysData(firstDay.toJSON(), lastDay.toJSON(),id).then(
            data => {
                if(data && data.data)
                {
                    this.setState({rangeOfDays: data.data});
                }                
            },
            error => {this.setState({ rangeOfDays: null });}
        ); 
    }
    enableFirstStep(value)
    {
        this.setState({ selectedDay: value });
    }
    enableSecondStep(value)
    {
        this.setState({ selectedTime: value });
    }

    enableThirdStep(value)
    {
        this.setState({ selectedNote: value });
    }
    bookAppointment()
    {
        const {selectedTime,selectedDay, selectedNote} = this.state;
        var idEnc = this.props.match.params.companyStoreIdEnc;
        if(idEnc != '' && selectedDay)
        {
            userService.BookAppointment(selectedTime, selectedDay,selectedNote, idEnc).then(
                data => {
                    if(data && data.ok)
                    {                        
                        const { from } = { from: { pathname: "/Booking/List" } };
                        this.props.history.push(from);
                    }                    
                },
                error => {window.$('#bookingModelError').modal('show');}
            ); 
        }
    }
    render() {
        const {rangeOfDays,selectedDay} = this.state;
        return (
            <div>
                <section className="ts-separate-bg-element" data-bg-image="/assets/img/bg-desk.jpg" data-bg-image-opacity=".1" style={{ paddingTop: 6 + "rem", height:100+"vh"}} data-bg-color="#1b1464">
                <Navbar />
                <Modal id="bookingModelError" classtype='modal-error' icon='fa fa-close' title='Errore!' description='Prenotazione fallita, riprovare.' />
                <div className="container h-100">
                    <div className="row justify-content-center pt-5">
                        <div className="col-4 align-items-center">
                            <div id="infoCalendar" style={{ display: "block" }} >
                                <HelpAppointment description="Scegliere un giorno dal calendario." />
                            </div>
                            <div id="infoDay" style={{ display: "none" }} >
                                <HelpAppointment description="Scegliere la fascia oraria preferita." />
                            </div>
                            <div id="infoNote" style={{ display: "none" }}>
                                <HelpAppointment description="Allega delle note alla prenotazione, saranno visibili all'esercente." />
                            </div>
                        </div>
                        <div className="col-6" >
                            <div style={{height: 50+"vh"}} className="justify-item-center align-items-center" >
                                <div id="inputCalendar" style={{ display: "block" }}><InputCalendar rangeOfDays={rangeOfDays} callbackMonthChange={this.callbackMonthChange} enableFirstStep={this.enableFirstStep} /></div>
                                <div id="inputDay"  style={{ display: "none", paddingTop: 11+"vh" }}><InputDay enableSecondStep={this.enableSecondStep} /></div>
                                <div id="inputNote"  style={{ display: "none", paddingTop: 11+"vh" }}><InputNote enableThirdStep={this.enableThirdStep} bookAppointment={this.bookAppointment} /></div>
                            </div>
                            <hr style={{backgroundColor:"white"}}/>
                            {
                                selectedDay ?  <div className="row" style={{color:"#f26d7d"}} >
                                <div className="col float-left">
                                    <a id="arrowBack" style={{display: "none", cursor: "pointer"}} onClick={this.onClickBack}><i className="fa fa-caret-left app-arrow" aria-hidden="true"></i> Indietro</a>
                                </div>
                                <div className="col float-right" style={{textAlign:"end"}}>
                                    <a id="arrowNext" style={{ cursor: "pointer"}} onClick={this.onClickNext}>Avanti <i className="fa fa-caret-right app-arrow" aria-hidden="true" ></i></a>
                                </div>
                            </div>
                            : <></>
                                
                            }                           
                           
                        </div>
                    </div>
                </div>
                <Footer />
                </section>
            </div>
        )
    }
}

export default Appointment;